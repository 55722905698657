import { types } from 'mobx-state-tree';
import {
  Feature,
  SoftlineType,
  Tag,
} from '~/components/Blocks/Templates/Softline/types';

export const DistrListModel = types.model('DistrListModel', {
  code: (types.string, ''),
  name: (types.string, ''),
  url: (types.string, ''),
});
export const KeyListModel = types.model('KeyListModel', {
  key: (types.string, ''),
  value: (types.string, ''),
});

export const ShippingsForSubscriptionsModel = types.model(
  'ShippingsForSubscriptionsModel',
  {
    simId: types.maybeNull(types.number),
    guideList: types.maybeNull(types.frozen<Record<string, string>>()),
    distrList: types.maybeNull(types.array(DistrListModel)),
    keyList: types.maybeNull(types.array(KeyListModel)),
  },
);

export const TrialInfoModel = types.model('TrialInfoModel', {
  trialDays: types.maybeNull(types.number),
  trialPrice: types.maybeNull(types.number),
  isEnabled: (types.boolean, false),
  trialEndDate: types.maybeNull(types.string),
});

export const CashbackModel = types.model('CashbackModel', {
  cashbackPercent: types.maybeNull(types.number),
});

/** Описание колонок подписки */
export const DescriptionBlockFieldModel = types.model(
  'DescriptionBlockFieldModel',
  {
    header: types.maybe(types.string),
    lines: types.maybe(types.array(types.string)),
  },
);

/** Описание подписки */
export const DescriptionModel = types.model('DescriptionModel', {
  descriptionText: types.maybe(types.string),
  descriptionColumns: types.maybe(types.array(DescriptionBlockFieldModel)),
  descriptionSlider: types.maybe(types.array(types.string)),
});

export const ImageDataModel = types.model('ImageDataModel', {
  width: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  mime: types.maybeNull(types.string),
  wUnits: types.maybeNull(types.string),
  hUnits: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

export const SoftlineSubscriptionsModel = types.model(
  'SoftlineSubscriptionsModel',
  {
    statusString: types.maybe(types.maybeNull(types.string)),
    simId: types.maybe(types.maybeNull(types.number)),
    /** Дата, с которой возможно отключение подписки */
    banTrimDate: types.maybe(types.maybeNull(types.string)),
    /** Дата, с которой возможно отключение подписки,
     * учитывается и banTrimDate, и +1 день если banTrimDate уже прошёл)
     * */
    availableUnbindDt: types.maybe(types.maybeNull(types.string)),
    /** Дата отключения подписки */
    pendingUnbindDate: types.maybe(types.maybeNull(types.string)),
    name: types.maybe(types.maybeNull(types.string)),
    metricId: types.maybe(types.maybeNull(types.number)),
    price: types.maybe(types.maybeNull(types.number)),
    /** Срок действия подписки в днях */
    durationDays: types.maybe(types.maybeNull(types.number)),
    /** Информация о промо-периоде */
    trialInfo: types.maybe(types.maybeNull(TrialInfoModel)),
    /** Кешбэк */
    chatlingCashback: types.maybe(types.maybeNull(CashbackModel)),
    nameFromMethod: types.maybe(types.string),
    key: types.maybe(types.maybeNull(types.string)),
    footer: types.maybe(types.string),
    image: types.maybe(types.maybeNull(types.string)),
    /** Описание */
    description: types.maybe(DescriptionModel),
    /** Число устройств на подписке */
    deviceCount: types.maybe(types.maybeNull(types.number)),
    imageInfo: types.maybe(types.maybeNull(ImageDataModel)),
    operatingSystem: types.maybe(types.maybeNull(types.string)),
    actions: types.maybe(
      types.maybeNull(types.frozen<Record<string, string>>()),
    ),
  },
);

export const BannerModel = types.model('', {
  url: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  mainText: types.maybeNull(types.string),
  buttonText: types.maybeNull(types.string),
  buttonTextMobile: types.maybeNull(types.string),
});

export const FieldsModel = types.model('FieldsModel', {
  /** список антивирусов */
  features: types.maybeNull(types.frozen<Record<string, Feature>>()),
  imageSizes: types.maybeNull(types.array(ImageDataModel)),
  banner: types.maybe(BannerModel),
});

export const SoftlineFieldsModel = types.model('FieldsModel', {
  /** список антивирусов */
  features: types.maybeNull(types.frozen<Record<string, Feature>>()),
  imageSizes: types.maybeNull(types.array(ImageDataModel)),
  /** теги */
  tags: types.maybeNull(types.frozen<Record<string, Tag>>()),
  /** тип ленты */
  type: types.maybe(types.enumeration(Object.values(SoftlineType))),
  /** код подписки для short блока */
  singleCardId: types.maybe(types.number),
  enhancedFields: types.maybe(types.array(SoftlineSubscriptionsModel)),
});
