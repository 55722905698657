import router from 'next/router';
import { store } from '../../components/AuthWizard/store/useMakeAuthStore';
import { AuthStoreInstance } from '~/stores/AuthStore';

const responseHandler = (response: Response) => {
  const { status } = response;
  if (status === 401) {
    const qParams = router.query;
    delete qParams.slug;
    AuthStoreInstance.removeAuth();
    store.setIsVisible(true);
  }
};

export default responseHandler;
