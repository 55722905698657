/** ошибка, которая отображается в крайнем случае */
export const DEFAULT_ERROR = 'DefaultException';

/** сообщения об ошибках ввода */
export enum ERROR_MESSAGE {
  AuthCountException = 'Неправильный номер договора и/или пароль',
  ContractNotServicedException = 'Договор не обслуживается',
  ContractNotFoundException = 'Договор не найден',
  BlockException = 'Доступ в\u00A0раздел «Моя\u00A0Планета» временно заблокирован',
  ArgumentNullException = 'Введите пароль',
  DefaultException = 'Что-то пошло не так',
}

export enum RESPONSE_STATUS {
  status400 = 400,
  status500 = 500,
}

/** Название сп для открытия после авторизации */
export enum SIDE_PAGES {
  PAYMENT = 'payment',
  IPV6_ACTION = 'ipv6Action',
  IPV6_AGREEMENT = 'ipv6Agreement',
  /** Приостановка обслуживания */
  SUSPENDING_AGREEMENT = 'suspendingAgreement',
  SUSPENDING_ACTION = 'suspendingAction',
  /** Возобновление обслуживания */
  RESUMPTION_ACTION = 'resumptionAction',
  /** Доступ в ЛК без пароля */
  ACCESS_WITHOUT_PASSWORD_ACTION = 'accessWithoutPasswordAction',
  /** Изменить пароль */
  CHANGE_PASSWORD_ACTION = 'changePasswordAction',
  /** Изменить пин-код */
  CHANGE_PIN_ACTION = 'changePinAction',
  /** Изменить уведомления в контактах */
  CHANGE_NOTIFICATION = 'changeNotification',
  /** Удалить контакт */
  DELETE_CONTACT = 'deleteContact',
  /** Добавить контакт */
  ADD_CONTACT = 'addContact',
  /** Подтвердить контакт */
  VERIFY_CONTACT = 'verifyContact',
  /** Настройки тихого часа */
  CHANGE_MUTE = 'changeMute',
  /** Карточка оборудования */
  DEVICES = 'devices',
  DEVICES_AGREEMENT = 'devicesAgreement',
  DEVICES_ACTION = 'devicesAction',
  /** Подключение Гарантия+ */
  WARRANTY_AGREEMENT = 'warrantyAgreement',
  WARRANTY_ACTION = 'warrantyAction',
  /** Информация о возврате оборудования */
  REGRESS_DEVICE = 'regressDevice',
}
