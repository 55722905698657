/** libraries */
import { types, flow, Instance, applySnapshot } from 'mobx-state-tree';
import { formatISO } from 'date-fns';
import RouterContext from 'next/router';
/** constants */
import {
  CALL_FROM_SIDEPAGE_KEY,
  CONNECTION_SLUG,
  CONNECTION_TYPE,
  DEFAULT_STATE,
  POSSIBILITY_KEY,
  USER_ID,
} from '../constants';
import {
  CELLPHONE_VALIDATE_REGEXP,
  DEFAULT_ERROR,
  DEMAND_CAUSE,
  WORKFLOW_IDS,
} from '~/constants/common';
import { EMAIL_CLASS_ID } from '~/components/Blocks/Shared/ContactsAndNotifications/constants';
/** api */
import { createConnectionDemand, createContractDemand } from '~/api/api';
import { createDemand, getContacts } from '~/api/apiPab2c';
/** interfaces */
import {
  ConnectionDemand,
  Source,
  DemandType,
  StatusCode,
} from '../ConnectionWizard.types';
import { toJS } from 'mobx';

const PhoneModel = types.model('Phone', {
  value: (types.string, ''),
  forSend: (types.string, ''),
});

const SelectModel = types.model('Select', {
  value: (types.string, ''),
  label: (types.string, ''),
});

const ConnectionModel = types
  .model('ConnectionModel', {
    phone: PhoneModel,
    name: (types.string, ''),
    select: types.maybeNull(SelectModel),
    street: (types.string, ''),
    house: (types.string, ''),
    apartment: (types.string, ''),
    completedData: types.optional(types.map(types.boolean), {}),
    isVisibleTimeout: (types.boolean, false),
    isVisible: (types.boolean, false),
    isTPAvailable: (types.boolean, false),
    isAgreementChecked: (types.boolean, false),
    isErrorAgreement: (types.boolean, false),
    connectionPossibilityLogInfo: (types.string, ''),
    technicalPossibilityTP: (types.number, 0),
    technicalPossibilityArea: (types.number, 0),
    errorPhone: (types.boolean, false),
    serverError: (types.string, ''),
    isDemandLoading: (types.boolean, false),
    showChangeConnectionTypeSP: (types.boolean, false),
    typeOfApplication: (types.string, ''),
    /** Тип заявки на подключение авторизованного клиента */
    connectionType: types.maybeNull(
      types.enumeration(Object.values(CONNECTION_TYPE)),
    ),
    /** Телефон клиента, прикрепленный к договору */
    contractPhone: PhoneModel,
  })
  .views((self) => {
    return {
      get isAgreementNeed() {
        return ![CONNECTION_TYPE.newAddress, CONNECTION_TYPE.moving].includes(
          self.connectionType,
        );
      },
    };
  })
  .views((self) => ({
    get completed() {
      return toJS(self.completedData);
    },
  }))
  .actions((self) => {
    return {
      setPhone: (phone) => {
        self.phone = phone;
      },
      setContractPhone: (phone) => {
        self.contractPhone = phone;
      },
      setName: (name) => {
        self.name = name;
      },
      setSelect: (select) => {
        self.select = select;
      },
      setStreet: (street) => {
        self.street = street;
      },
      setHouse: (house) => {
        self.house = house;
      },
      setApartment: (apartment) => {
        self.apartment = apartment;
      },
      setErrorPhone: (errorPhone) => {
        self.errorPhone = errorPhone;
      },
      setCompleted: (key: string, value: boolean) => {
        self.completedData.set(key, value);
      },
      setIsVisibleTimeout: (isVisibleTimeout) => {
        self.isVisibleTimeout = isVisibleTimeout;
      },
      setIsTPAvailable: (isTPAvailable) => {
        self.isTPAvailable = isTPAvailable;
      },
      setConnectionPossibilityLogInfo: (connectionPossibilityLogInfo) => {
        self.connectionPossibilityLogInfo = connectionPossibilityLogInfo;
      },
      setTechnicalPossibilityTP: (technicalPossibilityTP) => {
        self.technicalPossibilityTP = technicalPossibilityTP;
      },
      setTechnicalPossibilityArea: (technicalPossibilityArea) => {
        self.technicalPossibilityArea = technicalPossibilityArea;
      },
      setShowChangeConnectionTypeSP: (showChangeConnectionTypeSP) => {
        self.showChangeConnectionTypeSP = showChangeConnectionTypeSP;
      },
      setTypeOfApplication: (typeOfApplication) => {
        self.typeOfApplication = typeOfApplication;
      },
      setIsAgreementChecked: (isAgreementChecked) => {
        self.isAgreementChecked = isAgreementChecked;
      },
      setIsErrorAgreement: (isErrorAgreement) => {
        self.isErrorAgreement = isErrorAgreement;
      },
      setIsDemandLoading: (isDemandLoading) => {
        self.isDemandLoading = isDemandLoading;
      },
      setIsVisible: (isVisible) => {
        self.isVisible = isVisible;
      },
      createDemand: flow(function* (
        isAvailable: boolean,
        comment?: string,
        tariff?: string,
      ) {
        const validatePhone = CELLPHONE_VALIDATE_REGEXP.test(
          self.phone.forSend.replace('+7', '8'),
        );
        if (self.serverError) {
          self.serverError = '';
        }
        if (!validatePhone) {
          self.errorPhone = true;
        }
        if (!self.isAgreementChecked && self.isAgreementNeed) {
          self.isErrorAgreement = true;
        }
        if (
          self.errorPhone ||
          !validatePhone ||
          (!self.isAgreementChecked && self.isAgreementNeed)
        ) {
          return;
        }

        const demandData: ConnectionDemand = {
          connectionPossibilityLogInfo: self.connectionPossibilityLogInfo,
          street: self.street,
          house: self.house,
          phone: self.phone.forSend.replace('+7', '8'),
          comment: `${comment ? `${comment} ` : ''}${
            self.typeOfApplication ?? ''
          }`,
          tariff,
          city: self.select.label,
          dateTime: formatISO(new Date()),
          userId: USER_ID,
          flat: self.apartment,
          client: self.name,
          source: Source.planeta,
          type: isAvailable ? DemandType.contractType : DemandType.demandType,
          status: isAvailable ? StatusCode.contract : StatusCode.consideration,
          url: window.location.href,
        };
        self.isDemandLoading = true;

        // для статистики
        window.dataLayer = window.dataLayer || [];

        let result;
        if (isAvailable) {
          result = yield createContractDemand(demandData);
          window.dataLayer.push({ event: 'leadon' });
        } else {
          result = yield createConnectionDemand(demandData);
          if (self.street && self.house && self.apartment) {
            window.dataLayer.push({ event: 'leadoff' });
          } else {
            window.dataLayer.push({ event: 'lead' });
          }
        }

        if (result.status && result.status === 200) {
          const timeDemand = new Date().getTime();
          localStorage.setItem(
            tariff ? `timeDemand:${tariff}` : 'timeDemand',
            String(timeDemand),
          );
          localStorage.setItem(
            POSSIBILITY_KEY,
            self.technicalPossibilityTP.toString(),
          );
          self.completedData.set(tariff ?? CALL_FROM_SIDEPAGE_KEY, true);
          self.isVisibleTimeout = true;
        } else {
          self.serverError = DEFAULT_ERROR;
        }

        self.isDemandLoading = false;
      }),
      /** Переключает видимость Sidepage с компонентом wizard-connect */
      toggleConnectionWizardVisible: () => {
        self.isVisible = !self.isVisible;
      },
      /** Создает заявку в РИС из ProductSwitcher на смену типа подключения */
      connectionWizardAuto: flow(function* (
        seriesCode: string,
        seriesName: string,
        isAuth,
        auth,
      ) {
        self.serverError = '';
        self.isDemandLoading = true;
        if (!isAuth) return;
        self.showChangeConnectionTypeSP = true;
        try {
          const res = yield getContacts();
          const onlyPhones = res.filter(
            (contact) => contact.contactValueClassId !== EMAIL_CLASS_ID,
          );
          const onlyEmails = res.filter(
            (contact) => contact.contactValueClassId === EMAIL_CLASS_ID,
          );
          self.phone = {
            value: onlyPhones.length ? onlyPhones[0].value.slice(1) : '',
            forSend: onlyPhones.length ? onlyPhones[0].value.slice(1) : '',
          };

          const result = yield createDemand(
            WORKFLOW_IDS.CHANGE_CONNECTION_TYPE,
            'Смена продукта со сменой технологии с сайта',
            `${seriesName} со сменой технологии подключения.`,
            `${auth.clientName}`,
            `${
              onlyPhones.length
                ? `8${onlyPhones[0].value.slice(1)}`
                : 'Номер не найден'
            }`,
            onlyEmails.length ? `${onlyEmails[0].value}` : undefined,
            DEMAND_CAUSE.ADDITIONAL_SERVICES,
          );
          if (result.status && result.status === 200) {
            const timeDemand = new Date().getTime();
            localStorage.setItem(
              seriesCode ? `timeDemand:${seriesCode}` : 'timeDemand',
              String(timeDemand),
            );
            self.completedData.set(seriesCode ?? CALL_FROM_SIDEPAGE_KEY, true);
          } else {
            self.serverError = DEFAULT_ERROR;
          }
        } catch (e) {
          const err = JSON.parse(e.errorMessage);
          self.serverError = err.Type;
          console.error('createDemand(ОКП: связаться с клиентом)', e);
        } finally {
          self.typeOfApplication = '';
          self.isDemandLoading = false;
        }
      }),
      /** Создает заявку в РИС на переезд */
      connectionWizardMoving: flow(function* (comment) {
        const validatePhone = CELLPHONE_VALIDATE_REGEXP.test(
          self.phone.forSend.replace('+7', '8'),
        );
        if (self.serverError) {
          self.serverError = '';
        }
        if (!validatePhone) {
          self.errorPhone = true;
        }
        if (!self.isAgreementChecked && self.isAgreementNeed) {
          self.isErrorAgreement = true;
        }
        if (
          self.errorPhone ||
          !validatePhone ||
          (!self.isAgreementChecked && self.isAgreementNeed)
        ) {
          return;
        }

        self.isDemandLoading = true;
        try {
          const result = yield createDemand(
            WORKFLOW_IDS.NEW_SALE,
            'Заявка на переезд',
            comment,
            self.name,
            self.contractPhone.forSend,
            undefined,
          );
          if (result.status && result.status === 200) {
            const timeDemand = new Date().getTime();
            localStorage.setItem('timeDemand', String(timeDemand));
            self.completedData.set(CALL_FROM_SIDEPAGE_KEY, true);
          } else {
            self.serverError = DEFAULT_ERROR;
          }
        } catch (e) {
          const err = JSON.parse(e.errorMessage);
          self.serverError = err.Type;
          console.error('createDemand', e);
        } finally {
          self.typeOfApplication = '';
          self.isDemandLoading = false;
        }
      }),
      /** Обработка закрытия виджета в SidePage */
      onCloseClickSidePageConnection: () => {
        self.isVisible = false;
        const url = new URL(document.location.href);
        const pathname = url.pathname.replace(`/${CONNECTION_SLUG}`, '');
        RouterContext.router.push(
          `${url.origin}${pathname}`,
          `${url.origin}${pathname}`,
          {
            shallow: true,
          },
        );
      },
      handleAgreementChecked: (e: React.ChangeEvent<HTMLInputElement>) => {
        self.isAgreementChecked = e.target.checked;
        self.isErrorAgreement = false;
      },
      setConnectionType: (type) => {
        self.connectionType = type;
        self.errorPhone = false;
        if (self.isAgreementNeed) self.isErrorAgreement = false;
      },
      resetConnectionStore: () => {
        /** Не сбрасывает значение isVisible, чтобы сохранить видимость СП при сбросе состояния */
        applySnapshot(self, {
          ...DEFAULT_STATE,
          isVisible: self.isVisible,
          select: self.select,
        });
      },
    };
  });

export default ConnectionModel;
export type IConnectionStore = Instance<typeof ConnectionModel>;
