/** библиотеки */
import React, { ReactNode, ReactElement } from 'react';
import { nanoid } from 'nanoid';
import { parseISO, format } from 'date-fns';
import { ru } from 'date-fns/locale';

import ReactHTMLParser, {
  convertNodeToElement,
  processNodes,
} from 'react-html-parser';

/** компоненты библиотеки */
import Link from 'next/link';
import {
  H1,
  H2,
  H3,
  Text,
  Link as CordisLink,
  OrderedList,
  UnorderedList,
  ListItem,
  Button,
  ButtonStyleTypes,
  LeadingText,
  Snoska,
  Icons,
} from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';

import {
  PromoInfoProps,
  PromoPriceInfoProps,
  PromoPricePrices,
  ImageSizeProps,
} from './Shared.types';
/** api */
import { getFeedBySlug } from '~/api/api';

/** стили */
import {
  StyledMdxLeadingText,
  StyledMdxSnoska,
  StyledMdxParagraph,
} from './Shared.style';

/** утилиты */
import { isExternal } from '~/utils/utils';

/** типы */
import {
  TAG_TYPE_KEY,
  TARGET,
  TagType,
  STORIES,
  STYLE_TYPE,
  WIZARD_TYPE,
  LOGIN,
} from '~/components/Blocks/Templates/TextHtml/TextHtml.types';
import { CARD_ICONS } from '../Templates/Payment/constants';
import {
  CALL_FROM_SIDEPAGE_KEY,
  CONNECTION_TYPE,
} from '~/components/ConnectionWizard/constants';
import { DefaultImgs } from '../Templates/Device/Device.types';

/** constants */
import { DEVICES } from '~/constants/common';
import { DEFAULT_IMAGE } from '../Templates/Pab2c/Devices/constants';

/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/* Вычисление акций для продукта и оборудования
 * @promoConnection{PromoConnectionInfosProps[]} - массив акций
 * @currentPrice{number} - текущая стоимость
 * @currentAnnuity{number} - текущая стоимость рассрочки
 * @currentDownPayment{number} - текущий первоначальный взнос
 * */
export const getPromoPrice = (
  promoConnection: PromoInfoProps[],
  currentPrices: PromoPricePrices,
): PromoPriceInfoProps => {
  if (promoConnection?.length > 0) {
    // Текущая дата
    const currentDate = new Date();
    /* Сортировка акций по цене */
    const sortByPrice = (
      prev: PromoInfoProps,
      next: PromoInfoProps,
    ): number => {
      if (prev > next) return 1;
      if (prev < next) return -1;
      return 0;
    };
    const {
      price: promoPrice,
      annuity: promoAnnuity,
      downPayment: promoDownPayment,
      finishDt,
      maxPromoDevice,
    } = promoConnection
      .filter(
        (item) =>
          new Date(item.startDt) <= currentDate &&
          (item.finishDt ? new Date(item.finishDt) >= currentDate : true),
      )
      .sort(sortByPrice)[0];
    return {
      promoPrice,
      promoAnnuity,
      promoDownPayment,
      finishDt,
      maxPromoDevice,
    };
  }
  return {
    promoPrice: currentPrices.currentPrice,
    promoAnnuity: currentPrices.currentAnnuity,
    promoDownPayment: currentPrices.currentDownPayment,
  };
};

/**
 * Масштабирует размеры изображения по большей стороне (width|height) с учётом максимального размера.
 * Если одна из сторон больше максимального размера
 * @param imgWidth Ширина изображения
 * @param imgHeight Высота изображения
 * @param maxSize Максимальный размер изображения
 * @param ignoreDefaultSize Игнорирование условия, при котором первоначальный размер меньше, чем требуется
 */
export const getImageSize = (
  imgWidth: number,
  imgHeight: number,
  maxSize: number,
  ignoreDefaultSize = false,
): ImageSizeProps => {
  if (imgWidth <= maxSize && imgHeight <= maxSize && !ignoreDefaultSize)
    return {
      newWidth: imgWidth,
      newHeight: imgHeight,
    };
  let ratio;
  if (imgWidth > imgHeight) {
    ratio = maxSize / imgWidth;
  } else {
    ratio = maxSize / imgHeight;
  }
  return {
    newWidth: Math.floor(ratio * imgWidth),
    newHeight: Math.floor(ratio * imgHeight),
  };
};

/**
 * HTML теги
 */
export const htmlComponents = {
  h1: (children: ReactNode, isStories?: string): JSX.Element => (
    <H1 key={nanoid(5)} isStories={!!isStories}>
      {children}
    </H1>
  ),
  h2: (children: ReactNode, isStories?: string): JSX.Element => (
    <H2 key={nanoid(5)} isStories={!!isStories}>
      {children}
    </H2>
  ),
  h3: (children: ReactNode, isStories?: string): JSX.Element => (
    <H3 key={nanoid(5)} isStories={!!isStories}>
      {children}
    </H3>
  ),
  a: (href: string, target: string, children: ReactNode): JSX.Element => {
    return isExternal(href) || target ? (
      <CordisLink
        key={nanoid(5)}
        href={decodeURIComponent(href)}
        target="_blank"
      >
        {children}
      </CordisLink>
    ) : (
      <Link key={nanoid(5)} href={decodeURIComponent(href)}>
        <span>
          <CordisLink href={decodeURIComponent(href)}>{children}</CordisLink>
        </span>
      </Link>
    );
  },
  p: (children: ReactNode): JSX.Element => (
    <StyledMdxParagraph key={nanoid(5)}>
      <Text>{children}</Text>
    </StyledMdxParagraph>
  ),
  li: (children: ReactNode): JSX.Element => (
    <ListItem key={nanoid(5)}>{children}</ListItem>
  ),
  ol: (children: ReactNode): JSX.Element => (
    <OrderedList key={nanoid(5)}>{children}</OrderedList>
  ),
  ul: (children: ReactNode): JSX.Element => (
    <UnorderedList key={nanoid(5)} showBullet>
      {children}
    </UnorderedList>
  ),
  strong: (children: ReactNode): JSX.Element => (
    <Text key={nanoid(5)} style={{ display: 'inline', fontWeight: 'bold' }}>
      {children}
    </Text>
  ),
  LeadingText: (children: ReactNode): JSX.Element => (
    <StyledMdxLeadingText key={nanoid(5)}>
      <LeadingText>{children}</LeadingText>
    </StyledMdxLeadingText>
  ),
  Snoska: (children: ReactNode): JSX.Element => (
    <StyledMdxSnoska key={nanoid(5)}>
      <Snoska>{children}</Snoska>
    </StyledMdxSnoska>
  ),
  Button: (
    children: ReactNode,
    href?: string,
    styleType?: ButtonStyleTypes,
    /** Предустановленный тип подключения авторизованного клиента */
    wizardType?: CONNECTION_TYPE,
    /** Открывает СП входа в ЛК */
    isLogin?: boolean,
  ): JSX.Element => {
    const { toggleVisible } = useMakeAuthStore();
    const {
      toggleConnectionWizardVisible,
      setConnectionType,
      completed,
    } = useConnectionStore();
    const buttonHandler = () => {
      if (isLogin) return toggleVisible();
      if (wizardType && !completed?.get(CALL_FROM_SIDEPAGE_KEY)) {
        setConnectionType(wizardType);
      }
      return toggleConnectionWizardVisible();
    };
    return (
      <React.Fragment key={nanoid(5)}>
        {href ? (
          <LinkWrapper href={href}>
            <Button styleType={styleType ?? ButtonStyleTypes.MAIN}>
              {children as string}
            </Button>
          </LinkWrapper>
        ) : (
          <Button
            styleType={styleType ?? ButtonStyleTypes.MAIN}
            onClick={buttonHandler}
          >
            {children as string}
          </Button>
        )}
      </React.Fragment>
    );
  },
};

/** Если в ленте есть блок, перемещает скролл до него */
export const scrollToBlockById = (
  blockId: string,
  behavior: 'auto' | 'smooth' = 'smooth',
  block: 'start' | 'center' | 'nearest' = 'start',
): void => {
  const element = document.getElementById(blockId);
  if (!element) {
    return;
  }

  setTimeout(() => {
    element.scrollIntoView({ behavior, block });
  });
};

/**
 * Преобразует ссылки в полученной строке в компонент библиотеки
 * @param html строка html
 */
export const parseHtml = (html: string): ReactNode => {
  const transform = (node, index): ReactNode => {
    if (node.type === 'tag') {
      const isStories = node.attribs[STORIES];
      switch (node.name) {
        case 'h1':
          return htmlComponents.h1(
            processNodes(node.children, transform),
            isStories,
          );
        case 'h2':
          return htmlComponents.h2(
            processNodes(node.children, transform),
            isStories,
          );
        case 'h3':
          return htmlComponents.h3(
            processNodes(node.children, transform),
            isStories,
          );
        case 'a':
          const href = node.attribs?.href || '/';
          const target = node.attribs[TARGET];
          return htmlComponents.a(
            href,
            target,
            processNodes(node.children, transform),
          );
        case 'p':
          return htmlComponents.p(processNodes(node.children, transform));
        case 'li':
          return htmlComponents.li(processNodes(node.children, transform));
        case 'ol':
          return htmlComponents.ol(processNodes(node.children, transform));
        case 'ul':
          return htmlComponents.ul(processNodes(node.children, transform));
        case 'strong':
          return htmlComponents.strong(processNodes(node.children, transform));
        case 'span':
          const tagTypeAttr = node.attribs[TAG_TYPE_KEY];
          switch (tagTypeAttr) {
            case TagType.LEADING_TEXT:
              return htmlComponents.LeadingText(
                processNodes(node.children, transform),
              );
            case TagType.SNOSKA:
              return htmlComponents.Snoska(
                processNodes(node.children, transform),
              );
            default:
              return convertNodeToElement(node, index, transform);
          }
        case 'button':
          const tagBtnTypeAttr = node.attribs[TAG_TYPE_KEY];
          const styleType = node.attribs[STYLE_TYPE];
          const wizardType = node.attribs[WIZARD_TYPE];
          const isLogin = Boolean(node.attribs[LOGIN]);
          switch (tagBtnTypeAttr) {
            case TagType.BTN_HREF:
              const btnHref = node.attribs?.href || '/';
              return htmlComponents.Button(
                processNodes(node.children, transform),
                btnHref,
                styleType,
              );
            default:
              return htmlComponents.Button(
                processNodes(node.children, transform),
                null,
                styleType,
                wizardType,
                isLogin,
              );
          }
        default:
          return convertNodeToElement(node, index, transform);
      }
    }
    return convertNodeToElement(node, index, transform);
  };

  const options = {
    transform,
  };

  return ReactHTMLParser(html, options);
};

/** Получает дату */
export const getDate = (
  dateString: string,
  dateFormat = 'd MMMM yyyy',
): string => {
  const dateISO = parseISO(dateString);
  return format(dateISO, dateFormat, { locale: ru });
};

/**
 * Получает иконку платёжной системы
 * @param paymentSystem - код платёжной системы.
 * String, так как в самой библиотеке не типизировали ответ https://github.com/braintree/credit-card-type/blob/main/src/types.ts#L39
 */
export const chooseIconForSelect = (
  paymentSystem: string,
): ReactElement | null => {
  switch (paymentSystem) {
    case CARD_ICONS.VISA:
      return <Icons.BlackVisaIcon />;
    case CARD_ICONS.MASTERCARD:
      return <Icons.MastercardIcon />;
    case CARD_ICONS.MIR:
      return <Icons.BlackMirIcon />;
    case CARD_ICONS.JCB:
      return <Icons.BlackJcbIcon />;
    case CARD_ICONS.UNIONPAY:
      return <Icons.BlackUnionPayIcon />;
    case CARD_ICONS.NONAME_CARD:
      return <Icons.SmallCardIcon />;
    case CARD_ICONS.SBP:
      return <Icons.SbpIcon />;
    default:
      return null;
  }
};

/**
 * Получаем коллекцию ссылок на изображения для дефолтного оборудования
 */
export const getDefaultDeviceImgs = async (): Promise<DefaultImgs[]> => {
  const devicesFeatures = await getFeedBySlug(DEVICES, false);
  const deviceBlock = devicesFeatures.blocks.find(
    (item) => item.template === DEVICES,
  );
  const defaultImgs = Object.keys(deviceBlock.fields.defaultIds).map((key) => {
    const deviceId = deviceBlock.fields.defaultIds[key];
    const imgLink =
      deviceBlock.fields.features[deviceId].images ?? DEFAULT_IMAGE;
    return { type: key, imgLink };
  });
  return defaultImgs;
};
