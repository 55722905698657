import { types } from 'mobx-state-tree';

export const SatelliteCodeModel = types.maybeNull(
  types.union(types.literal(1), types.literal(-1), types.literal(2)),
);
const SatellitePrefixedNameModel = types.maybeNull(
  types.union(
    types.literal('(3435) Н.Тагил'),
    types.literal('(343) Екатеринбург'),
    types.literal('(3439) Каменск-Уральский'),
  ),
);

export const SatelliteModel = types.model({
  satellite: SatelliteCodeModel,
  prefixedName: SatellitePrefixedNameModel,
});

export const PhoneModel = types.model({
  sim: types.number,
  phoneNumber: types.number,
  prefix: types.number,
  unbindBanTrimDate: types.string,
  canUnbind: types.boolean,
  unbindDate: types.maybeNull(types.string),
  isPasswordSet: types.boolean,
});

export const SelectPropsModel = types.model({
  label: types.string,
  value: types.string,
});

export const StatisticPropsModel = types.model({
  date: types.string,
  duration: types.number,
  fromNumber: types.string,
  isIncome: types.boolean,
  phoneNumber: types.string,
  sum: types.number,
});

export const ResultPropsModel = types.model({
  isResult: types.boolean,
  isCorrect: types.boolean,
  text: types.maybe(types.string),
});

const CodePriceModel = types.model({
  directionCode: types.string,
  name: types.string,
  price: types.number,
  tariffZoneName: types.string,
});

const DayOfTheWeekIntervalModel = types.model({
  fromDate: types.string,
  trimDate: types.string,
  begin: types.string,
  end: types.string,
});

const DayOfTheWeekModel = types.model({
  isWorking: types.boolean,
  interval: DayOfTheWeekIntervalModel,
});

export const ForwardingRuleModel = types.model({
  id: types.number,
  sim: types.number,
  forwardNumber: types.string,
  state: types.string,
  isEnabled: types.boolean,
  timeout: types.number,
  price: types.array(CodePriceModel),
  timetable: types.model({
    monday: types.maybeNull(DayOfTheWeekModel),
    tuesday: types.maybeNull(DayOfTheWeekModel),
    wednesday: types.maybeNull(DayOfTheWeekModel),
    thursday: types.maybeNull(DayOfTheWeekModel),
    friday: types.maybeNull(DayOfTheWeekModel),
    saturday: types.maybeNull(DayOfTheWeekModel),
    sunday: types.maybeNull(DayOfTheWeekModel),
  }),
});

export const PriceModel = types.model({
  code: types.string,
  name: types.string,
  price: types.number,
  groupCode: types.string,
});

export const AgreementModel = types.model({
  dateBind: types.string,
  dateUnbind: types.maybe(types.string),
  dateSuckEnd: types.maybe(types.string),
  unbindBanTrimDate: types.string,
  rentalFee: types.number,
  rentalFeePaymentPeriodCode: types.maybe(types.string),
  rentalOnce: types.number,
  agreement: types.string,
});
