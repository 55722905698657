import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getCardPaymentAutoPaymentInfo } from '~/api/api';
import { toJS } from 'mobx';

const RequestsStateModel = types.model('State', {
  getAutoPaymentData: createApiPathModel(
    'GET /CardPayment/CardPayment/CardPaymentAutoPaymentInfo',
  ),
});

const AutoPaymentDataModel = types.model('AutoPaymentData', {
  /** Минимальная сумма автоплатежа */
  minPayment: types.maybeNull(types.number),
  /** Максимальная сумма автоплатежа */
  maxPayment: types.maybeNull(types.number),
  /** Идентификатор привязанной карты */
  bindingId: types.maybeNull(types.number),
  /** Сумма автоплатежа */
  payment: types.maybeNull(types.number),
  /** Сумма автоплатежа, рассчитанная автоматически на текущий момент */
  autoCalcPayment: types.maybeNull(types.number),
  /** Флаг активации */
  isActive: (types.boolean, false),
  /** Дата следующего платежа */
  payDate: types.maybeNull(types.string),
  /** Флаг автоматического рассчета суммы автоплатежа */
  hasAutoCalc: types.maybeNull(types.boolean),
  paymentThreshold: types.maybeNull(types.number),
});

export const AutoPaymentInfoStore = types
  .model('AutoPaymentInfo', {
    requestsState: RequestsStateModel,
    autoPayment: types.maybeNull(AutoPaymentDataModel),
  })
  .views((self) => ({
    get isLoadingAutoPayment() {
      return self.requestsState.getAutoPaymentData.isLoading;
    },
    get autoPaymentData() {
      return toJS(self.autoPayment);
    },
  }))
  .actions((self) => ({
    getAutoPaymentData: flow(function* (temporaryToken?: string) {
      self.requestsState.getAutoPaymentData.reset();
      self.requestsState.getAutoPaymentData.setLoading();
      try {
        const res = yield getCardPaymentAutoPaymentInfo(temporaryToken);
        self.autoPayment = res;
        self.requestsState.getAutoPaymentData.setSuccess();
      } catch (e) {
        self.requestsState.getAutoPaymentData.setFail();
      }
    }),
    setAutoPaymentData: (autoPayment) => {
      self.autoPayment = autoPayment;
    },
    resetAutoPaymentData: () => {
      self.autoPayment = null;
    },
  }));

export const AutoPaymentInfoStoreInstance = AutoPaymentInfoStore.create({
  requestsState: {
    getAutoPaymentData: defaultModelState,
  },
  autoPayment: null,
});
