import * as React from 'react';
import { Provider as MobxProvider } from 'mobx-react';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { ThemeProvider } from '@emotion/react';
import GlobalStyles from '~/components/GlobalStyles';
import { RootStoreInstance } from '~/stores/RootStore';

interface Props {
  children?: JSX.Element;
}

const MainProvider = ({ children }: Props): JSX.Element => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const body = (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <MobxProvider {...RootStoreInstance}>{children}</MobxProvider>
    </ThemeProvider>
  );

  // prevents ssr flash for mismatched dark mode
  if (!mounted) {
    return <div style={{ visibility: 'hidden' }}>Loading...</div>;
  }

  return body;
};

export default MainProvider;
