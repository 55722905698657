import { types, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { getPab2cDevices } from '~/api/apiPab2c';
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { OWNERSHIP_CODES } from '~/components/Blocks/Templates/Pab2c/Devices/constants';

const RequestsStateModel = types.model('State', {
  getPab2cDevices: createApiPathModel(
    'GET /Device/ContractDevice/GetContractDevices',
  ),
});

const WarrantyPlusInfoModel = types.model({
  banTrimDate: types.maybeNull(types.string),
  isAvailable: types.boolean,
  isOrdered: types.boolean,
  price: types.maybeNull(types.number),
});

export const Pab2cDeviceModel = types.model({
  annuity: types.maybeNull(types.number),
  availabilityDt: types.string,
  deviceModelId: types.number,
  deviceTypeCode: types.string,
  inLease: types.boolean,
  isLeasingAvailable: types.maybeNull(types.boolean),
  leaseDays: types.maybeNull(types.number),
  leaseSaldo: types.maybeNull(types.number),
  modelName: types.string,
  ownership: types.enumeration(Object.values(OWNERSHIP_CODES)),
  ownershipEndDays: types.maybeNull(types.number),
  priceOff: types.maybeNull(types.number),
  priceOn: types.maybeNull(types.number),
  purchasePrice: types.maybeNull(types.number),
  serial: types.maybeNull(types.string),
  sim: types.number,
  warrantyEndDate: types.string,
  warrantyPlusInfo: WarrantyPlusInfoModel,
  id: types.maybe(types.number),
});

export const Pab2cDevicesModel = types
  .model({
    requestsState: RequestsStateModel,
    pab2cDevices: types.array(Pab2cDeviceModel),
  })
  .views((self) => ({
    get pab2cDevicesList() {
      return toJS(self.pab2cDevices);
    },
    get isLoading() {
      return self.requestsState.getPab2cDevices.isLoading;
    },
    get isResult() {
      return (
        !self.requestsState.getPab2cDevices.isLoading &&
        (self.requestsState.getPab2cDevices.hasError ||
          self.requestsState.getPab2cDevices.isSucceeded)
      );
    },
    get isCorrect() {
      return (
        self.requestsState.getPab2cDevices.isFetched &&
        !self.requestsState.getPab2cDevices.hasError
      );
    },
  }))
  .actions((self) => {
    return {
      updatePab2cDevices: flow(function* () {
        self.requestsState.getPab2cDevices.reset();
        self.requestsState.getPab2cDevices.setLoading();
        try {
          const res = yield getPab2cDevices();
          self.pab2cDevices = res;
          self.requestsState.getPab2cDevices.setSuccess();
        } catch (e) {
          self.requestsState.getPab2cDevices.setFail();
        }
      }),
      resetPab2cDevices: () => {
        self.pab2cDevices.clear();
      },
    };
  });

export const Pab2cDevicesStoreInstance = Pab2cDevicesModel.create({
  requestsState: {
    getPab2cDevices: defaultModelState,
  },
  pab2cDevices: [],
});
