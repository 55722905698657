export const IMAGES = {
  vacationSmall: `${process.env.STATIC_SERVER}/static/images/vacation/vacation_size_1.png`,
  vacationBig: `${process.env.STATIC_SERVER}/static/images/vacation/vacation_size_3.png`,
  vacation: `${process.env.STATIC_SERVER}/static/images/vacation/vacation_size_3_2.png`,
  vacationSm: `${process.env.STATIC_SERVER}/static/images/vacation/vacation_small.png`,
};

/** Состояние услуги "Каникулы" */
export enum VACATION_STATE {
  /** Доступна */
  available = 'Available',
  /** Заказана */
  ordered = 'Ordered',
  /** Недоступна */
  unavailable = 'Unavailable',
  /** Активирована */
  activated = 'Activated',
}

export enum VACATION_UNVAILABLE_REASON {
  /** Недостаточно денег */
  notEnoughMoney = 'NotEnoughMoney',
  /** Включена добровольная приостановка обслуживания */
  enabledSuspending = 'EnabledSuspending',
  /** Запланирована добровольная приостановка обслуживания */
  scheduledSuspending = 'ScheduledSuspending',
  /** Некорректное состояние договора */
  notCorrectContractState = 'NotCorrectContractState',
  /** Не доступен для тарифа */
  unavailableForTariff = 'UnavailableForTariff',
}
