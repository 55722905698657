import { maskPhone } from '~/utils/utils';
import { Phone } from './interfaces';
import { eachDayOfInterval, parseISO } from 'date-fns';

export const checkDirectionPhoneNumber = (number: string): string => {
  if (number.length > 11) {
    return number.startsWith('+7') ? 'internal' : 'external';
  }

  if (number.length === 11) {
    return /^[78]/.test(number) ? 'internal' : 'external';
  }

  return 'internal';
};

export const convertRuPhoneNumberForQuery = (number: string): string => {
  return number.replace(/^(\D|8)/, '');
};

export const convertExPhoneNumberForQuery = (number: string): string => {
  return number.replace(/^(\D|810)/, '');
};

export const maskHomePhone = (item: Phone): string => {
  if (!item) return '';
  const phone = `${item.prefix}${item.phoneNumber}`;
  const phonePart = item.phoneNumber
    .toString()
    .match(/(\d{0,2})(\d{0,2})(\d{0,2})/);
  return item.prefix.toString().length === 3
    ? maskPhone(phone).replace('+7', '8').replace(/[()]/g, '')
    : `8 ${item.prefix} ${phonePart[1]}-${phonePart[2]}-${phonePart[3]} `;
};

/** Получить значение минимального числа дней подключения номера */
export const getMinRentalTime = (
  startDate: string,
  lastDate: string,
): number => {
  const minRentalTime = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(lastDate),
  });
  return minRentalTime.length - 1;
};
