import styled from '@emotion/styled';

/**
 * Styled-component для формирования внутренней ссылки
 */
const StyledLinkWrapper = styled.div`
  a:hover {
    text-decoration: none;
  }
`;

export default StyledLinkWrapper;
