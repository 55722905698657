import { differenceInMonths, format, parseISO, setDay } from 'date-fns';
import { Feed } from 'feed';
import * as fs from 'fs';
import { ru } from 'date-fns/locale';

/** типы */
import { AnnouncementContentProps } from '~/components/Blocks/Templates/Announcements/Announcements.types';
import {
  FeaturesProps,
  FieldsProps,
} from '~/components/Blocks/Templates/Devices/interfaces';
import { DotInfo } from '~/interfaces/ContractAuth.interface';

/** константы */
import { PLANETA_URL, CITIES } from '~/constants/common';

/** utils */
import serverImageConverter from './serverImageConverter';

/**
 * Возвращает строку в формате 123 345 567,89
 * @param amountNumber номер
 * @param hideDecimal спрятать числа после запятой
 * @param separatorChar разделитель, использующийся вместо точки
 * @param notHideDecimalInIntegers не прятать числа после запятой у целых чисел
 */
export const formatNumber = (
  amountNumber: number,
  hideDecimal?: boolean,
  separatorChar?: string,
  notHideDecimalInIntegers?: boolean,
): string => {
  const amount = amountNumber || 0;
  let res = amount
    .toFixed(hideDecimal ? 0 : 2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00A0')
    .replace('.', `${separatorChar ?? ','}`);
  if (!hideDecimal && res.endsWith(',00') && !notHideDecimalInIntegers) {
    res = res.replace(',00', '');
  }
  return res;
};

/**
 * Удаляет нули перед логином. Держать в уме, отсутствие поддержки b2b контрактов.
 * @param contract
 */
export const removeLeadingZero = (contract: string): string =>
  contract.replace(/^0+/, '');

/**
 * Склонение слова в зависимости от числа
 * @param {Number} number Число на основе которого нужно сформировать окончание
 * @param {Array} titles1 Массив слов (например ['символ', 'символа', 'символов'])
 * @param {Array} titles2 Массив слов (например ['Остался', 'Осталось', 'Осталось']) - необязательный параметр
 * @param {boolean} hideCount Флаг необходимости сокрытия числа при возврате результата (вернёт только склоняемое слово)
 * @param {boolean} isFormatNumber Флаг форматирования числа методом formatNumber() (например 12.5 -> 12,50)
 * @return {String} пример "Осталось 12 символов"
 */
export const pluralizeAll = (
  number: number,
  titles1: string[],
  titles2?: string[],
  hideCount?: boolean,
  isFormatNumber?: boolean,
): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  const roundedNumber = Math.floor(number);

  const wordVariant: number =
    roundedNumber % 100 > 4 && roundedNumber % 100 < 20
      ? 2
      : cases[roundedNumber % 10 < 5 ? roundedNumber % 10 : 5];

  const numberOutput = isFormatNumber ? formatNumber(number) : number;

  return `${titles2 ? `${titles2[wordVariant]} ` : ''}${
    !hideCount ? numberOutput : ''
  }${'\u00A0'}${titles1[wordVariant]}`;
};

/* yaspeller ignore:start */
/**
 * Возвращает первое вхождение директории по url
 * например 'http://localhost:3000/overtv/hitsuper20'
 * вернёт   'overtv'
 * @param {string} url ссылка (например 'http://localhost:3000/overtv/hitsuper20')
 * @returns {string}
 */
export const parseDirectoryUrl = (url: string): string => {
  /** шаблон для поиска */
  const result = url.match(/(\w+:\/\/)(([A-z-.0-9А-я]+)(:\d+)?)\/(\w+)/);
  /** если найдено возвращает пятую группу поиска */
  return result ? result[5] : '';
};
/* yaspeller ignore:end */

/**
 * Проверяет, является ли ссылка внешней
 * @param {string} url ссылка
 * @returns {string}
 */
export const isExternal = (url: string): boolean => {
  if (!url || url.length === 0) return false;
  const match = url.match(
    /^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/,
  );
  if (
    typeof match[1] === 'string' &&
    match[1].length > 0 &&
    match[1].toLowerCase() !== window.location.protocol
  )
    return true;
  if (
    typeof match[2] === 'string' &&
    match[2].length > 0 &&
    match[2].replace(
      new RegExp(
        `:(${{ 'http:': 80, 'https:': 443 }[window.location.protocol]})?$`,
      ),
      '',
    ) !== window.location.host
  )
    return true;
  return false;
};

/**
 * Возвращает имя клиента из строки, содержащей ФИО
 * @param clientName
 */
export const getClientName = (clientName: string): string => {
  const clientNameSegments = clientName.split(' ');

  if (clientNameSegments.length >= 2) {
    return clientNameSegments[1];
  }

  return clientName;
};

/**
 * Формирует текст приветствия исходя из времени суток
 */
export const getGreetingText = (): string => {
  const GREETING_TEXT = {
    MORNING: 'Доброе утро',
    AFTERNOON: 'Добрый день',
    EVENING: 'Добро пожаловать домой',
    NIGHT: 'Доброй ночи',
  };
  const dateNow = new Date();
  const nowH = dateNow.getHours();
  const nowM = dateNow.getMinutes();
  const nowS = dateNow.getSeconds();
  let text = '';

  switch (true) {
    case nowH >= 0 && nowH < 6:
      text = GREETING_TEXT.NIGHT;
      break;
    case nowH >= 6 && nowH < 12:
      text = GREETING_TEXT.MORNING;
      break;
    case nowH >= 12 && nowH < 18:
      text = GREETING_TEXT.AFTERNOON;
      break;
    case nowH >= 18 && nowH < 23:
      text = GREETING_TEXT.EVENING;
      break;
    case nowH === 23 && nowM <= 59 && nowS <= 59:
      text = GREETING_TEXT.EVENING;
      break;
    default:
      text = '';
  }
  return text;
};

/**
 * Форматирует телефон для вывод на экран +7 (999) 999-99-99
 * @param phoneNumber
 * @returns string
 */
export const maskPhone = (phoneNumber: string) => {
  // получаем телефон формата 9990009999 (без +7 чисто цифры)
  let phone = phoneNumber.replace('+7', '').replace(/[^0-9]/g, '');

  if (phone.length && (phone[0] === '8' || phone[0] === '7'))
    phone = phone.slice(1, phone.length);

  // дробление телефона на части
  const phonePart = phone.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/) || '';

  return `+7 ${
    !phonePart[2]
      ? phonePart[1]
      : `(${phonePart[1]}) ${phonePart[2]}${
          phonePart[3] ? `-${phonePart[3]}` : ''
        }${phonePart[4] ? `-${phonePart[4]}` : ''}`
  }`;
};

/**
 * Вычисляет количество месяцев из количества дней
 * Например:
 * 365 => 12
 * @param {number} daysCount Количество дней
 * @returns {number} Количество месяцев
 */
export const calcMonth = (daysCount: number): number => {
  return differenceInMonths(setDay(new Date(), daysCount), new Date()) + 1;
};

/**
 * Генерирует RSS-поток
 * @param {AnnouncementContentProps[]} articles Массив новостей
 */
export const generateRSSFeed = (articles: AnnouncementContentProps[]): void => {
  /** Добавление новостей в rss-ленту */
  Object.keys(CITIES).forEach((city) => {
    const feed = new Feed({
      id: PLANETA_URL,
      title: 'Планета: Новости',
      language: 'ru-RU',
      feedLinks: {
        rss2: `${PLANETA_URL}/rss.xml`,
      },
      link: PLANETA_URL,
      description: 'Новости компании «Планета»',
      copyright: '© planeta.tc',
    });

    articles
      .filter((post) => {
        return !post.cities || post.cities.some(CITIES[city]);
      })
      .forEach((post) => {
        const { publishedDt, title, fullSlug, rssDesc } = post;

        const url = `${PLANETA_URL}/${fullSlug}`;

        feed.addItem({
          title,
          content: rssDesc,
          id: url,
          link: url,
          date: new Date(publishedDt),
        });
      });

    fs.writeFileSync(`public/${city}/news/rss`, feed.rss2());
  });
};

export const getDevicesFeatures = async (
  fields: FieldsProps,
  features: FeaturesProps,
) => {
  const newDevices = [];

  const images = Object.keys(features).map((item) =>
    /\/static/.test(features[item].images)
      ? `${process.env.STATIC_SERVER_INTERNAL}${features[item].images}`
      : features[item].images,
  );
  const convertedImages = await serverImageConverter(images, 145);
  const imageSizes = convertedImages.map((image) => ({
    ...image,
    url: image.url.replace(process.env.STATIC_SERVER_INTERNAL, ''),
  }));

  const convertedImagesWizard = await serverImageConverter(images, 220);
  const imageSizesInWizard = convertedImagesWizard.map((image) => ({
    ...image,
    url: image.url.replace(process.env.STATIC_SERVER_INTERNAL, ''),
  }));

  // Заполнение массива оборудования
  Object.keys(fields as FieldsProps).forEach((key) => {
    if (!(+key >= 0)) return;
    newDevices.push(fields[key]);

    // Добавление в объект оборудования его дополнительных свойств
    Object.keys(features).forEach((feature) => {
      const id = Number(feature);
      if (id === fields[key].id) {
        const imageSize = imageSizes.find(
          (item) => item.url === features[feature].images,
        );
        const imageSizeInWizard = imageSizesInWizard.find(
          (item) => item.url === features[feature].images,
        );
        newDevices[key] = {
          ...newDevices[key],
          ...features[feature],
          imageSize,
          imageSizeInWizard,
        };
      }
    });
  });

  return [
    ...newDevices.filter((item) => item.sort).sort((a, b) => a.sort - b.sort),
    ...newDevices.filter((item) => !item.sort),
  ];
};

/** Возвращает наименование файла из url */
export const getFileNameFromUrl = (url: string): string =>
  decodeURI(url).match(/([^\/]+)(?=\.\w+$)/)[0];

/** Получает дату */
export const getDate = (
  dateString: string,
  dateFormat = 'd MMMM yyyy',
): string => {
  const dateISO = parseISO(dateString);
  return format(dateISO, dateFormat, { locale: ru });
};

/** Склеивает данные адреса, получаемые методом GetContractInfo, в одну строку */
export const getDotAddress = (dotInfo: DotInfo): string => {
  if (!dotInfo) return '';
  const roomName = dotInfo.roomName ? `, кв. ${dotInfo.roomName}` : '';
  return `${dotInfo.streetName}, д. ${dotInfo.buildingName}${roomName}`;
};
