/** libraries */
import { createContext, useContext } from 'react';
import { types } from 'mobx-state-tree';
/** stores */
import { AgreementStore, AgreementStoreInstance } from './AgreementStore';
import {
  ConnectionTariffStore,
  ConnectionTariffStoreInstance,
} from './ConnectionTariffStore';
import { PreloaderStore, PreloaderStoreInstance } from './PreloaderStore';
import { CityStore, CityStoreInstance } from './CityStore';
import {
  ContractInfoStore,
  ContractInfoStoreInstance,
} from './ContractInfoStore';
import {
  Pab2cBalanceStore,
  Pab2cBalanceStoreInstance,
} from './Pab2cBalanceStore';
import {
  Pab2cDevicesModel,
  Pab2cDevicesStoreInstance,
} from './Pab2cDevicesStore';
import {
  DeferredPaymentModel,
  DeferredPaymentModelInstance,
} from './DeferredPaymentStore';
import {
  ProductHistoryStore,
  ProductHistoryStoreInstance,
} from './ProductHistoryStore';
import {
  ContractStateStore,
  ContractStateStoreInstance,
} from './ContractStateStore';
import { SummaryDataStore, SummaryDataStoreInstance } from './SummaryDataStore';
import { TVStore, TVStoreInstance } from './TVStore';
import {
  AllowedTariffStore,
  AllowedTariffStoreInstance,
} from './AllowedTariffStore';
import { Pab2cVoiceStore, Pab2cVoiceStoreInstance } from './Pab2cVoiceStore';
import { PaymentInfoStore, PaymentInfoStoreInstance } from './PaymentInfoStore';
import {
  AutoPaymentInfoStore,
  AutoPaymentInfoStoreInstance,
} from './AutoPaymentInfoStore';
import { SbpBanksStore, SbpBanksStoreInstance } from './SbpBanksStore';
import { SoftlineStoreModel, SoftlineStoreInstance } from './SoftlineStore';
import {
  AuthStore,
  AuthStoreInstance,
  initialAuthStoreState,
} from './AuthStore';
import { VacationStore, VacationStoreInstance } from './VacationStore';
import {
  ProlongationStore,
  ProlongationStoreInstance,
} from './ProlongationStore';

export interface RootStore {
  agreementStore: typeof AgreementStoreInstance;
  preloaderStore: typeof PreloaderStoreInstance;
  connectionTariffStore: typeof ConnectionTariffStoreInstance;
  cityStore: typeof CityStoreInstance;
  contractInfoStore: typeof ContractInfoStoreInstance;
  productHistoryStore: typeof ProductHistoryStoreInstance;
  pab2cBalanceStore: typeof Pab2cBalanceStoreInstance;
  pab2cDevicesStore: typeof Pab2cDevicesStoreInstance;
  deferredPaymentStore: typeof DeferredPaymentModelInstance;
  contractStateStore: typeof ContractStateStoreInstance;
  summaryDataStore: typeof SummaryDataStoreInstance;
  tvStore: typeof TVStoreInstance;
  allowedTariffStore: typeof AllowedTariffStoreInstance;
  pab2cVoiceStore: typeof Pab2cVoiceStoreInstance;
  paymentInfoStore: typeof PaymentInfoStoreInstance;
  autoPaymentInfoStore: typeof AutoPaymentInfoStoreInstance;
  sbpBanksStore: typeof SbpBanksStoreInstance;
  softlineStore: typeof SoftlineStoreInstance;
  authStore: typeof AuthStoreInstance;
  vacationStore: typeof VacationStoreInstance;
  prolongationStore: typeof ProlongationStoreInstance;
}

export const RootStoreModel = types.model('RootStore', {
  agreementStore: AgreementStore,
  preloaderStore: PreloaderStore,
  connectionTariffStore: ConnectionTariffStore,
  cityStore: CityStore,
  contractInfoStore: ContractInfoStore,
  productHistoryStore: ProductHistoryStore,
  pab2cBalanceStore: Pab2cBalanceStore,
  pab2cDevicesStore: Pab2cDevicesModel,
  deferredPaymentStore: DeferredPaymentModel,
  contractStateStore: ContractStateStore,
  summaryDataStore: SummaryDataStore,
  tvStore: TVStore,
  allowedTariffStore: AllowedTariffStore,
  pab2cVoiceStore: Pab2cVoiceStore,
  paymentInfoStore: PaymentInfoStore,
  autoPaymentInfoStore: AutoPaymentInfoStore,
  sbpBanksStore: SbpBanksStore,
  softlineStore: SoftlineStoreModel,
  authStore: AuthStore,
  vacationStore: VacationStore,
  prolongationStore: ProlongationStore,
});

export const RootStoreInstance = RootStoreModel.create({
  // сюда можно перетащить все контексты из main provider
  agreementStore: AgreementStoreInstance,
  preloaderStore: PreloaderStoreInstance,
  connectionTariffStore: ConnectionTariffStoreInstance,
  cityStore: CityStoreInstance,
  contractInfoStore: ContractInfoStoreInstance,
  productHistoryStore: ProductHistoryStoreInstance,
  pab2cBalanceStore: Pab2cBalanceStoreInstance,
  pab2cDevicesStore: Pab2cDevicesStoreInstance,
  deferredPaymentStore: DeferredPaymentModelInstance,
  contractStateStore: ContractStateStoreInstance,
  summaryDataStore: SummaryDataStoreInstance,
  tvStore: TVStoreInstance,
  allowedTariffStore: AllowedTariffStoreInstance,
  pab2cVoiceStore: Pab2cVoiceStoreInstance,
  paymentInfoStore: PaymentInfoStoreInstance,
  autoPaymentInfoStore: AutoPaymentInfoStoreInstance,
  sbpBanksStore: SbpBanksStoreInstance,
  softlineStore: SoftlineStoreInstance,
  authStore: initialAuthStoreState,
  vacationStore: VacationStoreInstance,
  prolongationStore: ProlongationStoreInstance,
});

const RootStoreContext = createContext<RootStore>(RootStoreInstance);

export const useRootStore = () => {
  return useContext(RootStoreContext);
};
