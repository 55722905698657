/* Значение тега по умолчанию */
export const DEFAULT_TAG = { value: 'Всё', tag: 'all', types: [] };

/* Значение тега "Мои подписки" */
export const MY_SUBSCRIPTIONS_TAG = {
  value: 'Мои подписки',
  tag: 'subscriptions',
  types: [],
};

/* Значение тега "Cashback" */
export const CASHBACK_TAG = {
  value: 'Кешбэк бонусами',
  tag: 'cashback',
  types: [],
};

/* Ссылка на изображение по умолчанию для антивируса */
export const DEFAULT_IMAGE = '/static/images/default_soft.png';

/** Промо-теги на карточках */
export enum SOFTLINE_PROMO_TAGS {
  PROMO = 'ПРОМО',
  ANNUAL = 'НА ГОД',
}

/** Текст для промо-тега кешбэка на карточках */
export const CASHBACK_TEXT = {
  value: (discount): string => `КЕШБЭК ${discount}% БОНУСАМИ`,
};

/** Годовая подписка */
export const ANNUAL_SUBSCRIPTION = 365;

/** Цвета карточки подписки */
export enum COLORS {
  REGULAR = 'white',
  BACK_MY_SUBSCRIPTION = 'backgroundOk',
  BORDER_MY_SUBSCRIPTION = 'green',
  BACK_WARNING = 'backgroundWarning',
  BORDER_WARNING = 'orangePeel',
}

/** Число дней до конца годовой подписки, когда начинаем показывать предупреждение */
export const daysUntilSubscriptionEnds = 14;
