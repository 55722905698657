export const DEFAULT_IMAGE = '/static/images/devices/default-image.jpg';

// Чувствительность swipe для мобильного устройства.
// Чем выше значение - тем больше расстояние нужно проделать для работы swipe
export const SWIPE_AMOUNT = 50;

// Заголовок блока мои устройства
export const TITLE_DEVICES_BLOCK = 'Моё оборудование';

export const OWNERSHIP_MAP = {
  Bought: 'В собственности',
  LeasingWithOwnership: 'В пользовании',
  Leasing: 'В пользовании',
  BoughtLeasing: 'Рассрочка',
};

export enum OWNERSHIP_CODES {
  /** в собственности */
  BOUGHT = 'Bought',
  /** пользование с передачей в собственность */
  LEASING_WITH_OWNERSHIP = 'LeasingWithOwnership',
  /** в пользовании */
  LEASING = 'Leasing',
  /** в рассрочке */
  BOUGHT_LEASING = 'BoughtLeasing',
}

export const OWNERSHIP_MAP_EXTENDED = {
  LeasingWithOwnership: 'Пользование с передачей в собственность',
  BoughtLeasing: 'Оборудование в рассрочке',
  Leasing: 'Оборудование в пользовании',
  Bought: 'Оборудование в собственности',
};

export const ACTION_MAP = {
  LeasingWithOwnership: {
    desktop: 'Получить в собственность',
    mobile: 'Получить',
  },
  BoughtLeasing: {
    desktop: 'Оплатить досрочно',
    mobile: 'Оплатить',
  },
  Leasing: {
    desktop: 'Купить оборудование',
    mobile: 'Купить',
  },
  BuyInLeasing: {
    desktop: 'Купить в рассрочку',
    mobile: 'Рассрочка',
  },
  Bought: {
    desktop: '',
    mobile: '',
  },
};

export const PAYMENT_PERIOD_MAP = {
  daily: 'в день',
  monthly: 'в месяц',
  weekly: 'в неделю', // ?
};

/* Время анимации (ms) */
export const ANIMATION_DURATION = 1000;

/** типы устройств */
export enum DEVICE_TYPES {
  phone = 'Телефон',
  stb = 'Телеприставка',
  router = 'Маршрутизатор',
  handset = 'Гарнитура',
  camera = 'Камера',
  /* eslint-disable camelcase */
  remote_control = 'Пульт',
  /* eslint-disable camelcase */
  smart_speaker = 'Умная колонка',
  media_converter = 'Медиаконвертер',
  switch = 'Коммутатор',
}

/** типы устройств */
export enum DEVICE_TYPES_GENITIVE {
  phone = 'Телефона',
  stb = 'Телеприставку',
  router = 'Роутер',
  handset = 'Гарнитуру',
  camera = 'Камеру',
  /* eslint-disable camelcase */
  remote_control = 'Пульт',
  /* eslint-disable camelcase */
  smart_speaker = 'Умную колонку',
  switch = 'Коммутатор',
}

export const SUCCESS_RESULT_CODE = 'Ok';

export const INTERNAL_ERROR = 'InternalError';

export const DEFAULT_ERROR = 'Что-то пошло не так. Повторите попытку позже.';

// state result по умолчанию
export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: true,
  text: '',
};

export const initialLeasingPayment = {
  value: '0 ₽',
  forSend: 0,
};
