import { FC } from 'react';
import { css, Global } from '@emotion/react';
import fonts from '../Typography/fonts';

const GlobalMarkdownStyles: FC = () => {
  return (
    <Global
      styles={css`
        ${fonts}
      `}
    />
  );
};

export default GlobalMarkdownStyles;
