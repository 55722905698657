import { FC } from 'react';
import { css, Global, Theme, useTheme } from '@emotion/react';

const ResetStyles: FC = () => {
  const theme: Theme = useTheme();
  return (
    <Global
      styles={css`
        * {
          border: 0;
          box-sizing: inherit;
          font-weight: inherit;
          margin: 0;
          padding: 0;
        }
        html {
          display: flex;
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
          box-sizing: border-box;
          font-family: Inter, sans-serif;
          font-size: 18px;
          color: #050505;
          background-color: ${theme.colors.light};
          padding: 0;
          margin: 0;
        }
        body {
          margin: 0;
          width: 100%;
          height: 100%;
        }
        sup {
          font-size: 10px;
          line-height: 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      `}
    />
  );
};

export default ResetStyles;
