export enum AUTH_METHOD {
  TEMPORARY_TOKEN = 'TemporaryToken',
  PASSWORD = 'Password',
  PIN = 'Pin',
  DEMO_PASSWORD = 'DemoPassword',
  IP = 'IP',
}
export interface DotInfo {
  cityId: number;
  streetName: string;
  buildingName: string;
  roomName: string;
}
/** Авторизационные данные Контракта */
export interface ContractAuth {
  /** Идентификатор договора */
  contractId: number;
  /** Имя/название клиента */
  clientName: string;
  /** Идентификатор договора */
  contractName: string;
  /** Идентификатор города */
  cityId: number;
  /** Баланс */
  balance: number;
  /** Адрес точки подключения */
  dotInfo: DotInfo;
  /** Токен */
  token: string;
  /** Способ аутентификации */
  authenticationMethod: AUTH_METHOD;
  otherClientContractNames: string[];
}
