import { css } from '@emotion/react';

const fonts = css`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Regular/Inter-Regular.woff2?v=3.12')
        format('woff2'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Regular/Inter-Regular.woff?v=3.12')
        format('woff'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Regular/Inter-Regular.ttf')
        format('truetype'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Regular/Inter-Regular.svg')
        format('svg');
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Medium/Inter-Medium.woff2?v=3.12')
        format('woff2'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Medium/Inter-Medium.woff?v=3.12')
        format('woff'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Medium/Inter-Medium.ttf')
        format('truetype'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Medium/Inter-Medium.svg')
        format('svg');
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/SemiBold/Inter-SemiBold.woff2?v=3.12')
        format('woff2'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/SemiBold/Inter-SemiBold.woff?v=3.12')
        format('woff'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/SemiBold/Inter-SemiBold.ttf')
        format('truetype'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/SemiBold/Inter-SemiBold.svg')
        format('svg');
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    src: url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Bold/Inter-Bold.woff2?v=3.12')
        format('woff2'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/Inter/Bold/Inter-Bold.woff?v=3.12')
        format('woff'),
      url('${process.env.STATIC_SERVER}/static/fonts/Inter/Bold/Inter-Bold.ttf')
        format('truetype'),
      url('${process.env.STATIC_SERVER}/static/fonts/Inter/Bold/Inter-Bold.svg')
        format('svg');
    font-display: swap;
  }
  @font-face {
    font-family: 'TextBook';
    font-style: normal;
    src: url('${process.env.STATIC_SERVER}/static/chat/fonts/textbook.ttf')
      format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    src: url('${process.env.STATIC_SERVER}/static/chat/fonts/ProximaNova.ttf')
      format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'Druk Wide Cyr';
    font-style: normal;
    src: url('${process.env
          .STATIC_SERVER}/static/fonts/DrukWideCyr/DrukWideCyr-Medium.woff2')
        format('woff2'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/DrukWideCyr/DrukWideCyr-Medium.woff')
        format('woff'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/DrukWideCyr/DrukWideCyr-Medium.ttf')
        format('truetype'),
      url('${process.env
          .STATIC_SERVER}/static/fonts/DrukWideCyr/DrukWideCyr-Medium.svg')
        format('svg');
    font-display: swap;
  }
`;

export default fonts;
