import * as Sentry from '@sentry/nextjs';

/** Обёртка для отправки уведомления в Sentry об ошибке в api
 * @todo: существует более правильный вариант обработки таких ошибок, но он не работает с Next,
 * @todo: исправить после того как доделают https://github.com/getsentry/sentry-javascript/issues/3673
 * */

const sentryWrapper = (
  funcName: string,
  status: number,
  res: unknown,
): never => {
  Sentry.captureException(`
      ${funcName}, statusCode: ${status}, errorMessage: ${JSON.stringify(res)}
    `);
  const error = { statusCode: status, errorMessage: JSON.stringify(res) };
  throw error;
};

export default sentryWrapper;
