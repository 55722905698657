import { Block } from '~/interfaces/BlockInterface';

/** поля блока */
export interface TextHTMLFields {
  content: string;
  id?: string;
}

/** контент блока */
export interface TextHTMLContent extends Block {
  fields: TextHTMLFields;
}

/** свойства компонента */
export interface TextHTMLProps {
  content: TextHTMLContent;
}

/** дополнительный идентификатор для html тега */
export const TAG_TYPE_KEY = 'data-tag-type';
export const TARGET = 'target';
export const STORIES = 'stories';
export const STYLE_TYPE = 'style-type';
export const WIZARD_TYPE = 'wizard-type';
export const LOGIN = 'login';

/** дополнительное название для тега  */
export enum TagType {
  SNOSKA = 'snoska',
  LEADING_TEXT = 'leadingText',
  BTN_HREF = 'btnHref',
}
