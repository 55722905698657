export const CHATLE = ['Чатл', 'Чатла', 'Чатлов'];

export const HOURS = ['час', 'часа', 'часов'];
export const DAYS = ['день', 'дня', 'дней'];

export const DONE = 'Done';
export const TOTAL = 'Total';
export const FOLDS = 'folds';
export const ADMISSION = 'Поступление';
export const EXTRACT = 'Выписка';
export const DAY_TAX = 'dayTax';

/** Статус договора */
export enum OPERATING_STATE {
  /** Обслуживается */
  ON = 'On',
  /** Обслуживание приостановлено из-за клиентской добровольной блокировки */
  CLIENT_BLOCK = 'ClientBlock',
  /** Договор расторгнут */
  DREGS = 'Dregs',
  /** Договор - зомби */
  STRAY = 'Stray',
  /** Близкий к зомбированию договор */
  CLOSE_TO_STRAY = 'StrayClose',
  /** Новый договор (обслуживание ещё не начато) */
  NEW = 'New',
  /** Обслуживание заблокировано со стороны оператора за неуплату */
  PROVIDER_BLOCK_DEBT = 'ProviderBlockDebt',
}

export enum PRICES_CATEGORY {
  daily = 'День',
  thirtyDays = '30 дней',
}

export enum CATEGORIES {
  ProductCosts = 'Расходы по продукту',
  Television = 'Телевидение',
  Services = 'Сервисы и услуги',
  Antivirus = 'Антивирусы',
  Equipment = 'Оборудование',
}

export enum SUSPEND_STATE {
  order = 'Ordered',
  available = 'Available',
}
