import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getBalance } from '~/api/apiPab2c';

const BalanceModel = types.model('Balance', {
  moneyBalance: types.maybeNull(types.number),
  chatleBudgetSaldo: types.maybeNull(types.number),
  chatleReluxSaldo: types.maybeNull(types.number),
  lowBalanceThreshold: types.maybeNull(types.number),
  moneyRecommended: types.maybeNull(types.number),
  tariffDayTax: types.maybeNull(types.number),
});

const RequestsStateModel = types.model('State', {
  getBalance: createApiPathModel('GET /Finances/Contract/GetBalance'),
});

export const Pab2cBalanceStore = types
  .model('Pab2cBalanceStore', {
    requestsState: RequestsStateModel,
    pab2cBalance: types.maybeNull(BalanceModel),
  })
  .views((self) => ({
    get moneyBalance() {
      return self.pab2cBalance?.moneyBalance;
    },
    get chatleBudgetSaldo() {
      return self.pab2cBalance?.chatleBudgetSaldo;
    },
    get chatleReluxSaldo() {
      return self.pab2cBalance?.chatleReluxSaldo;
    },
    get lowBalanceThreshold() {
      return self.pab2cBalance?.lowBalanceThreshold;
    },
    get moneyRecommended() {
      return self.pab2cBalance?.moneyRecommended;
    },
    get tariffDayTax() {
      return self.pab2cBalance?.tariffDayTax;
    },
    get isErrorBalance() {
      return self.requestsState.getBalance.hasError;
    },
    get isLoadingBalance() {
      return self.requestsState.getBalance.isLoading;
    },
    /** Зарезервированные чатлы больше 0 */
    get isReservedChatles() {
      return self.pab2cBalance?.chatleBudgetSaldo > 0;
    },
    /** Чатлы больше 0 */
    get isChatles() {
      return self.pab2cBalance?.chatleReluxSaldo > 0;
    },
  }))
  .actions((self) => {
    return {
      getBalance: flow(function* () {
        self.requestsState.getBalance.reset();
        self.requestsState.getBalance.setLoading();
        try {
          const res = yield getBalance();
          self.pab2cBalance = res;
          self.requestsState.getBalance.setSuccess();
        } catch (e) {
          self.requestsState.getBalance.setFail();
        }
      }),
      resetPab2cBalance: () => {
        self.pab2cBalance = null;
      },
    };
  });

export const Pab2cBalanceStoreInstance = Pab2cBalanceStore.create({
  requestsState: {
    getBalance: defaultModelState,
  },
  pab2cBalance: null,
});
