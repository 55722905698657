import { flow, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import { toJS } from 'mobx';
/** api */
import { getSubscriptionInfos } from '~/api/api';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  ShippingsForSubscriptionsModel,
  SoftlineSubscriptionsModel,
} from './models/SoftlineModel';
/** utils */
import { isExternal } from '~/utils/utils';
/** constants */
import { DEFAULT_IMAGE } from '~/components/Blocks/Templates/Softline/constants';

const RequestsStateModel = types.model('State', {
  getSubscriptionsInfos: createApiPathModel('GET /Tax/Tax/GetTariffHistory'),
});

export const SoftlineStoreModel = types
  .model('SoftlineStore', {
    requestsState: RequestsStateModel,
    shippingsForSubscriptionsData: types.array(ShippingsForSubscriptionsModel),
    softlineSubscriptionsData: types.array(SoftlineSubscriptionsModel),
  })
  .views((self) => ({
    get softlineListAuthClient() {
      if (toJS(self.softlineSubscriptionsData).length === 0) return [];
      return toJS(self.softlineSubscriptionsData).filter(
        (item) => item?.statusString,
      );
    },
    get isFetchedSubscriptionsInfos() {
      return self.requestsState.getSubscriptionsInfos.isFetched;
    },
    get softlineSubscriptions() {
      return toJS(self.softlineSubscriptionsData);
    },
    get shippingsForSubscriptions() {
      return toJS(self.shippingsForSubscriptionsData);
    },
    get isLoadingSoftlineSubscriptions() {
      return self.requestsState.getSubscriptionsInfos.isLoading;
    },
    get isFewSubscriptions() {
      return (
        toJS(
          self.softlineSubscriptionsData.filter((item) => item?.statusString),
        ).length <= 1
      );
    },
  }))
  .actions((self) => ({
    setSoftlineSubscriptions: (softlineSubscriptions) => {
      self.softlineSubscriptionsData = softlineSubscriptions;
    },
    setShippingsForSubscriptions: (shippingsForSubscriptionsData) => {
      self.shippingsForSubscriptionsData = shippingsForSubscriptionsData;
    },
    resetSoftline: () => {
      self.shippingsForSubscriptionsData.clear();
      self.shippingsForSubscriptionsData.clear();
      self.softlineSubscriptionsData.clear();
      self.requestsState.getSubscriptionsInfos.reset();
    },
  }))
  .actions((self) => ({
    // Добавление в объект подписки его дополнительных свойств
    addAdditionalProperties: (
      list,
      features,
      imageSizes,
      simIdFromBind?: number,
    ) => {
      const newSoftlineList = [];
      Object.keys(list).forEach((key) => {
        if (!(+key >= 0)) return;
        newSoftlineList.push({
          ...list[key],
          key: nanoid(5),
          imageInfo: imageSizes.find(
            (item) =>
              `${item.url}` ===
              `${process.env.STATIC_SERVER_INTERNAL}${DEFAULT_IMAGE}`,
          ),
        });

        Object.keys(features).forEach((feature) => {
          const softImg = features[feature]?.image;
          const imgUrl = isExternal(softImg)
            ? softImg
            : `${process.env.STATIC_SERVER}${softImg}`;
          const imageInfo = toJS(imageSizes)
            ? toJS(imageSizes).find((item) => item.url === imgUrl)
            : newSoftlineList[key].imageInfo;
          if (features[feature].metricId === list[key].metricId)
            newSoftlineList[key] = {
              ...newSoftlineList[key],
              ...features[feature],
              name: features[feature].name ?? newSoftlineList[key].name,
              nameFromMethod: newSoftlineList[key].name,
              footer: features[feature].footer,
              imageInfo,
            };
        });
      });
      /** Сортировка по sort в Mongo */
      const sortedList = [
        ...newSoftlineList
          .filter((item) => item.sort)
          .sort((a, b) => a.sort - b.sort),
        ...newSoftlineList.filter((item) => !item.sort),
      ];
      if (simIdFromBind) {
        sortedList.sort((a) => (a.simId !== simIdFromBind ? 1 : -1));
      }
      return sortedList;
    },
  }))
  .actions((self) => ({
    getSubscriptionInfos: flow(function* (simIdFromBind?: number) {
      self.requestsState.getSubscriptionsInfos.reset();
      self.requestsState.getSubscriptionsInfos.setLoading();
      try {
        const res = yield getSubscriptionInfos();
        self.softlineSubscriptionsData = res;
        self.requestsState.getSubscriptionsInfos.setSuccess();
      } catch (e) {
        self.requestsState.getSubscriptionsInfos.setFail();
      }
    }),
  }));

export const SoftlineStoreInstance = SoftlineStoreModel.create({
  requestsState: {
    getSubscriptionsInfos: defaultModelState,
  },
  shippingsForSubscriptionsData: [],
  softlineSubscriptionsData: [],
});
