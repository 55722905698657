import { FC } from 'react';
import ResetStyles from './reset';
import MarkdownStyles from './markdown';

const GlobalStyles: FC = () => (
  <>
    <ResetStyles />
    <MarkdownStyles />
  </>
);

export default GlobalStyles;
