import { BONUS_SLUG, INPUT_PHONE_VALUE, PLANETA_URL } from '~/constants/common';

/** ссылка на ресурс с документом о персональных данных */
export const URL_PRIVACY_POLICY = `${PLANETA_URL}/static/upload-files/documents/ktc.politic.pdn.pdf`;

/** Вид технической возможности в здании. */
export enum TechnicalPossibilityType {
  // Отсутствует.
  MISSING = 0,
  // Присутствует во всем здании.
  PRESENT = 1,
  // Присутствует в здании с ограничениями.
  PRESENT_WITH_RESTRICTION = 2,
}

/** Ключ для привязки значений к компоненту Сайдпейджа, в котором расположен Визард. */
export const CALL_FROM_SIDEPAGE_KEY = 'sidepage';

/** Ключ для значения технической возможности в localStorage */
export const POSSIBILITY_KEY = 'possibility';

export const CONNECTION_SLUG = 'connection';

/** Тип заявки на подключение */
export const TYPE_OF_APPLICATION = {
  gigabit0: 'Подключение к гигабиту бесплатно',
  year: 'Годовой продукт',
  game: 'Игровой продукт',
  transformer: 'Трансформер',
  '12plus2': 'Годовой 12+2',
  hit6: 'Хит6',
};

export const TypeOfApplication = Object.keys(TYPE_OF_APPLICATION);

/** UserId - константа = 4 из описания к задаче https://ctms.itmh.ru/browse/DIRI523-3519 */
export const USER_ID = 4;

export const initialSelect = { value: '', label: '' };

/** Типы подключения для авторизованного клиента */
export enum CONNECTION_TYPE {
  newAddress = 'newAddress',
  moving = 'moving',
  friend = 'friend',
}

export const connectionLabels = {
  [CONNECTION_TYPE.newAddress]: 'Новый адрес',
  [CONNECTION_TYPE.moving]: 'Переезжаю',
  [CONNECTION_TYPE.friend]: 'Подключаю друга',
};

export const connectionText = {
  [CONNECTION_TYPE.newAddress]:
    'Вы&nbsp;подаёте заявку на&nbsp;подключение Планеты ещё по&nbsp;одному адресу',
  [CONNECTION_TYPE.moving]:
    'Вы&nbsp;переезжаете и&nbsp;подаёте заявку на&nbsp;подключение Планеты по&nbsp;новому адресу',
  [CONNECTION_TYPE.friend]: `Вы&nbsp;подаете заявку на&nbsp;подключение друга к&nbsp;Планете, чтобы вместе <a href="/${BONUS_SLUG}" target="_blank">получить бонусы.</a>`,
};

export const DEFAULT_STATE = {
  phone: INPUT_PHONE_VALUE,
  name: '',
  select: initialSelect,
  street: '',
  house: '',
  apartment: '',
  completed: {},
  isVisibleTimeout: false,
  isVisible: false,
  isTPAvailable: false,
  isAgreementChecked: false,
  isErrorAgreement: false,
  connectionPossibilityLogInfo: '',
  technicalPossibilityTP: TechnicalPossibilityType.MISSING,
  technicalPossibilityArea: 0,
  errorPhone: false,
  serverError: '',
  isDemandLoading: false,
  showChangeConnectionTypeSP: false,
  typeOfApplication: '',
  connectionType: null,
  contractPhone: INPUT_PHONE_VALUE,
};
