import { types, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { OPERATING_STATE } from '~/constants/common';
import { SUSPEND_STATE } from '~/components/Blocks/Templates/Pab2c/Contract/constants';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getOperatingState, getSuspendCondition } from '~/api/apiPab2c';

const RequestsStateModel = types.model('State', {
  getOperatingState: createApiPathModel(
    'GET /OperatingState/OperatingState/GetState',
  ),
  getSuspendCondition: createApiPathModel(
    'GET /OperatingState/OperatingState/GetSuspendCondition',
  ),
});

const SuspendConditionModel = types.model({
  suspendState: types.maybeNull(
    types.enumeration(Object.values(SUSPEND_STATE)),
  ),
  scheduledDt: types.maybeNull(types.string),
  availableDt: types.maybeNull(types.string),
});

export const ContractStateStore = types
  .model({
    requestsState: RequestsStateModel,
    contractState: types.maybeNull(
      types.enumeration(Object.values(OPERATING_STATE)),
    ),
    suspendConditionState: types.maybeNull(SuspendConditionModel),
  })
  .views((self) => ({
    get suspendCondition() {
      return toJS(self.suspendConditionState);
    },
    get isLoading() {
      return (
        self.requestsState.getOperatingState.isLoading ||
        self.requestsState.getSuspendCondition.isLoading
      );
    },
    get isResult() {
      return (
        !self.requestsState.getOperatingState.isLoading &&
        !self.requestsState.getSuspendCondition.isLoading &&
        (self.requestsState.getOperatingState.hasError ||
          self.requestsState.getSuspendCondition.hasError ||
          self.requestsState.getOperatingState.isSucceeded ||
          self.requestsState.getSuspendCondition.isSucceeded)
      );
    },
    get isCorrect() {
      return (
        (self.requestsState.getOperatingState.isFetched ||
          self.requestsState.getSuspendCondition.isFetched) &&
        !self.requestsState.getOperatingState.hasError &&
        !self.requestsState.getSuspendCondition.hasError
      );
    },
  }))
  .actions((self) => ({
    /** получает данные о состоянии договора */
    getOperatingState: flow(function* () {
      self.requestsState.getOperatingState.reset();
      self.requestsState.getOperatingState.setLoading();
      try {
        const res = yield getOperatingState();
        self.contractState = res;
        self.requestsState.getOperatingState.setSuccess();
      } catch (e) {
        self.requestsState.getOperatingState.setFail();
      }
    }),
    /** получает данные о заказе добровольной приостановки обслуживания на договоре */
    getSuspendCondition: flow(function* () {
      self.requestsState.getSuspendCondition.reset();
      self.requestsState.getSuspendCondition.setLoading();
      try {
        const res = yield getSuspendCondition();
        self.suspendConditionState = res;
        self.requestsState.getSuspendCondition.setSuccess();
      } catch (e) {
        self.requestsState.getSuspendCondition.setFail();
      }
    }),
    resetContractState: () => {
      self.contractState = null;
      self.suspendConditionState = null;
    },
  }))
  /** обновляет данные договора */
  .actions((self) => ({
    updateAuthCondition: flow(function* () {
      yield self.getOperatingState();
      yield self.getSuspendCondition();
    }),
  }));

export const ContractStateStoreInstance = ContractStateStore.create({
  requestsState: {
    getOperatingState: defaultModelState,
    getSuspendCondition: defaultModelState,
  },
  contractState: null,
  suspendConditionState: null,
});
