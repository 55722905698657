import { types } from 'mobx-state-tree';

export const defaultModelState = {
  isLoading: false,
  hasError: false,
  isSucceeded: false,
  error: '',
};

const createApiPathModel = (name: string) => {
  if (!name) return;

  const Model = types
    .model(name, {
      isLoading: types.boolean,
      hasError: types.boolean,
      isSucceeded: types.boolean,
      error: types.string,
    })
    .views((self) => ({
      get isFetching() {
        return self.isLoading;
      },
      get isFailed() {
        return !self.isLoading && self.hasError;
      },
      get isFetched() {
        return !self.isLoading && self.isSucceeded;
      },
    }))
    .actions((self) => ({
      reset: () => {
        self.isLoading = false;
        self.isSucceeded = false;
        self.hasError = false;
        self.error = '';
      },
      setLoading: () => {
        self.isLoading = true;
      },
      setFail: (error = '') => {
        self.isLoading = false;
        self.isSucceeded = false;
        self.hasError = true;
        self.error = error;
      },
      setSuccess: () => {
        self.isLoading = false;
        self.isSucceeded = true;
        self.hasError = false;
        self.error = '';
      },
    }));

  // eslint-disable-next-line consistent-return
  return Model;
};

export default createApiPathModel;
