/** libraries */
import { types, cast } from 'mobx-state-tree';
/** models */
import {
  TariffPropsModel,
  SummaryFieldsModel,
} from './models/ConnectionTariffModel';
/** types */
import { TariffProps } from '../components/Blocks/Templates/ProductSwitcher/interfaces';
import { SummaryFields } from '../components/Blocks/Templates/Summary/Summary.types';

export const ConnectionTariffStore = types
  .model('ConnectionTariffStore', {
    connectTariff: types.maybeNull(
      types.union(TariffPropsModel, SummaryFieldsModel),
    ),
  })
  .actions((self) => ({
    toggleChangeTariffWizardVisible(
      tariff?: TariffProps | SummaryFields,
    ): void {
      self.connectTariff = cast(tariff);
    },
  }));

export const ConnectionTariffStoreInstance = ConnectionTariffStore.create({
  connectTariff: null,
});
