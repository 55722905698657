import { TechnicalPossibilityType } from './constants';

/**
 * Source, на данный момент с одним значением planeta = 2
 * https://ctms.itmh.ru/browse/DIRI523-3519
 */
export enum Source {
  planeta = 2,
}
/**
 * DemandType со значениями «Для договора» contract_type = 1, «Для заявки» demand_type = 2.
 * https://ctms.itmh.ru/browse/DIRI523-3519
 */
export enum DemandType {
  contractType = 1,
  demandType = 2,
}

/**
 * StatusCode со значениями «В рассмотрении» consideration = 2, «Договор (ожидание договора)» contract = 10.
 * https://ctms.itmh.ru/browse/DIRI523-3519
 */
export enum StatusCode {
  consideration = 2,
  contract = 10,
}

/** Объект для заявки на подключение */
export interface ConnectionDemand {
  /** LogInfo */
  connectionPossibilityLogInfo: string | null;
  /** текущая дата/время */
  dateTime: string;
  userId: number;
  /** город */
  city: string;
  /** улица */
  street?: string;
  /** дом */
  house?: string;
  /** квартира */
  flat?: string;
  /** наименование продукта */
  tarif?: string;
  /** информация из поля "Как вас зовут" */
  client: string;
  /** номер телефона */
  phone: string;
  source: Source;
  /** url страницы, с которой вызван метод */
  url: string;
  type: DemandType;
  status: StatusCode;
  /** текст из конфигуратора пользователя */
  comment?: string;
  managerApi?: number;
  floor?: string;
  corp?: string;
  build?: string;
  email?: string;
  wifi?: boolean;
  informationSource?: number;
  refuseReason?: number;
  recallDt?: string;
  contract?: number;
  refuseReasonDescription?: string;
  informationSourceText?: string;
  tariff?: string;
  entrance?: string;
  yandexMetricaClientID?: string;
  googleAnalyticsClientID?: string;
}

export interface TCity {
  value: string;
  label: string;
  shortName?: string;
}

export interface AutocompleteItem {
  /** Значение */
  value: string;
  /** Подпись */
  label: string;
  /** Картинка */
  image?: string;
}

/** сообщения об ошибках ввода */
export enum ERROR_MESSAGE {
  EMPTY_PHONE = 'Введите корректный номер телефона',
}

/**
 * Props компонента ConnectionWizard
 */
export interface ConnectionWizardProps {
  // Находится ли компонент в блоке Call To Action. Если да - применит соответствующие стили
  isInCTA?: boolean;
  // Комментарий, который в случае CtA пробросится в компонент с кнопкой
  comment?: string;
  // Продукт, выбранный в CtA
  tariff?: string;
  // Код серии продукта.
  seriesCode?: string;
}

/**
 * Информация о технической возможности
 */
export interface TechnicalPossibility {
  connectionPossibilityLogInfo: string;
  tp: TechnicalPossibilityType;
  area: number;
}

export interface RequestDataTPProps {
  cityName: string;
  streetName: string;
  buildingName: string;
  flatName: string;
  fromUrl?: string;
  yandexMetricaClientID?: string;
  googleAnalyticsClientID?: string;
}

export interface Phone {
  value: string;
  forSend: string;
}
