import { types } from 'mobx-state-tree';

export const PreloaderStore = types
  .model('PreloaderStore', {
    isLoaderVisible: types.boolean,
  })
  .actions((self) => ({
    routeChangeStart: (url, { shallow }): void => {
      self.isLoaderVisible = true;
    },
    routeChangeEnd: (url): void => {
      self.isLoaderVisible = false;
    },
  }));

export const PreloaderStoreInstance = PreloaderStore.create({
  isLoaderVisible: false,
});
