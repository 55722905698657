import { types, flow, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getPaymentCards, getSbpBindings } from '~/api/api';

const RequestsStateModel = types.model('State', {
  getPaymentCards: createApiPathModel(
    'GET /CardPayment/CardPayment/GetPaymentCards',
  ),
  getSbpBindings: createApiPathModel('GET /Sbp/GetSbpBindings'),
});

const PaymentCardPropsModel = types.model({
  id: types.number,
  maskedPan: types.string,
  paymentSystem: types.maybeNull(types.string),
});

const SbpBindingsPropsModel = types.model({
  id: types.number,
  bankName: types.string,
});

export const PaymentInfoStore = types
  .model({
    requestsState: RequestsStateModel,
    paymentCardList: types.array(PaymentCardPropsModel),
    sbpBindingList: types.array(SbpBindingsPropsModel),
  })
  .views((self) => ({
    get paymentCards() {
      return toJS(self.paymentCardList);
    },
    get sbpBindings() {
      return toJS(self.sbpBindingList);
    },
    get haveLinkedCards() {
      return toJS(!!self.paymentCardList.length);
    },
    get haveLinkedSbpBindings() {
      return toJS(!!self.sbpBindingList.length);
    },
    get isLoading() {
      return (
        self.requestsState.getPaymentCards.isLoading ||
        self.requestsState.getSbpBindings.isLoading
      );
    },
    get isResult() {
      return (
        !self.requestsState.getPaymentCards.isLoading &&
        !self.requestsState.getSbpBindings.isLoading &&
        (self.requestsState.getPaymentCards.hasError ||
          self.requestsState.getSbpBindings.hasError ||
          self.requestsState.getPaymentCards.isSucceeded ||
          self.requestsState.getSbpBindings.isSucceeded)
      );
    },
    get isFetched() {
      return (
        (self.requestsState.getPaymentCards.isFetched ||
          self.requestsState.getSbpBindings.isFetched) &&
        !self.requestsState.getPaymentCards.hasError &&
        !self.requestsState.getSbpBindings.hasError
      );
    },
    get isCardsError() {
      return self.requestsState.getPaymentCards.hasError;
    },
    get isSbpError() {
      return self.requestsState.getSbpBindings.hasError;
    },
  }))
  .actions((self) => ({
    /** Получает информацию о привязанных банковских картах на договоре */
    getPaymentCards: flow(function* () {
      self.requestsState.getPaymentCards.reset();
      self.requestsState.getPaymentCards.setLoading();
      try {
        const res = yield getPaymentCards();
        applySnapshot(self.paymentCardList, res);
        self.requestsState.getPaymentCards.setSuccess();
      } catch (e) {
        console.error('getPaymentCards', e);
        self.requestsState.getPaymentCards.setFail();
      }
    }),
    /** Получает информацию о привязанных счетах СБП на договоре */
    getSbpBindings: flow(function* (token?: string) {
      self.requestsState.getSbpBindings.reset();
      self.requestsState.getSbpBindings.setLoading();
      try {
        const res = yield getSbpBindings(token);
        applySnapshot(self.sbpBindingList, res);
        self.requestsState.getSbpBindings.setSuccess();
      } catch (e) {
        console.error('getSbpBindings', e);
        self.requestsState.getSbpBindings.setFail();
      }
    }),
    resetPaymentInfoState: () => {
      self.requestsState.getSbpBindings.reset();
      self.requestsState.getPaymentCards.reset();
      applySnapshot(self.paymentCardList, []);
      applySnapshot(self.sbpBindingList, []);
    },
  }))
  .actions((self) => ({
    getPaymentInfo: flow(function* () {
      yield Promise.allSettled([self.getPaymentCards(), self.getSbpBindings()]);
    }),
  }));

export const PaymentInfoStoreInstance = PaymentInfoStore.create({
  requestsState: {
    getPaymentCards: defaultModelState,
    getSbpBindings: defaultModelState,
  },
  paymentCardList: [],
  sbpBindingList: [],
});
