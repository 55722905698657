/** libraries */
import { cast, types } from 'mobx-state-tree';
/** интерфейсы */
import { CityData } from '~/interfaces/CityData.interface';
/** утилиты */
import { cookies } from '~/api/api';
/** константы */
import {
  DEFAULT_CITY,
  CITY_DATA,
  COOKIES_CITY_DEAD_TIME_IN_DAYS,
} from '~/constants/common';

const CityModel = types.model({
  id: types.number,
  name: types.string,
  shortName: types.string,
  satellite: types.maybeNull(types.number),
});

const ContactPhoneModel = types.model({
  id: types.number,
  timeTable: types.maybe(types.string),
  cityId: types.number,
  isDefault: types.boolean,
  phone: types.string,
  type: types.string,
  clientClassCode: types.maybe(types.string),
});

const setCityCookies = (cityId) => {
  const cookiesDeadTime: Date = new Date();
  cookiesDeadTime.setDate(
    cookiesDeadTime.getDate() + COOKIES_CITY_DEAD_TIME_IN_DAYS,
  );
  cookies.set(CITY_DATA, { cityId }, { path: '/', expires: cookiesDeadTime });
};

export const CityStore = types
  .model('CityStore', {
    city: CityModel,
    cities: types.array(CityModel),
    contactPhones: types.array(ContactPhoneModel),
  })
  .actions((self) => {
    return {
      setCities: (cities) => {
        self.cities = cities;
      },
      setCity: (city) => {
        self.city = { ...city };
        setCityCookies(city.id);
      },
      setPhones: (phones) => {
        self.contactPhones = phones;
      },
      cityUpdate: (suggestedCity, authCityId?) => {
        const { cities } = self;
        const cityData: CityData = cookies.get(CITY_DATA);

        let selectedCityId = suggestedCity?.id;
        if (cityData?.cityId) {
          selectedCityId = cities.find((city) => city?.id === cityData?.cityId)
            ?.id;
        } else if (authCityId) {
          selectedCityId =
            cities.find((city) => city?.id === authCityId)?.id ??
            DEFAULT_CITY.id;
        }
        const updatedCity =
          cities.find((city) => city?.id === selectedCityId) ?? DEFAULT_CITY;
        self.city = cast({ ...updatedCity });
        setCityCookies(updatedCity.id);
      },
    };
  });

export const CityStoreInstance = CityStore.create({
  city: DEFAULT_CITY,
  cities: [DEFAULT_CITY],
  contactPhones: [],
});
