import { types } from 'mobx-state-tree';

export const AvailablePacketsModel = types.model('', {
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
});

export const ChannelModelNull = types.model('Channel', {
  id: types.maybeNull(types.number),
  idRis: types.maybeNull(types.number),
  isAccessible: (types.boolean, false),
  itvChannelLink: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  /** todo: всегда приходит null */
  singleChannelPacks: types.maybe(types.maybeNull(types.null)),
  timeshiftDepth: types.maybeNull(types.number),
  adultContent: types.maybe(types.boolean),
  audioStreams: types.maybe(types.maybeNull(types.array(types.string))),
  availablePackets: types.maybe(
    types.maybeNull(types.array(AvailablePacketsModel)),
  ),
  availablePlatforms: types.maybe(types.maybeNull(types.array(types.string))),
  channelDefinition: types.maybe(types.maybeNull(types.string)),
  description: types.maybe(types.maybeNull(types.string)),
  imgLink: types.maybe(types.maybeNull(types.string)),
  includedToProduct: types.maybe(types.maybeNull(types.boolean)),
  weburgId: types.maybe(types.maybeNull(types.number)),
  channelPackId: types.maybe(types.maybeNull(types.number)),
  banTrimDate: types.maybe(types.maybeNull(types.string)),
  unbindDt: types.maybe(types.maybeNull(types.string)),
});

export const SingleChannelPacksModel = types.model('SingleChannelPacks', {
  banTrimDate: types.maybeNull(types.string),
  channelPackChannels: types.maybe(
    types.maybeNull(types.array(ChannelModelNull)),
  ),
  channelPackName: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isChannelWrapper: (types.boolean, false),
  isFree: (types.boolean, false),
  isOrdered: (types.boolean, false),
  isSale: (types.boolean, false),
  /** Управление просмотром */
  isTimeshiftIncluded: (types.boolean, false),
  isTrial: (types.boolean, false),
  price: types.maybeNull(types.number),
  unbindDt: types.maybeNull(types.string),
});

export const ChannelModel = types.model('Channel', {
  id: types.maybeNull(types.number),
  idRis: types.maybe(types.number),
  isAccessible: (types.boolean, false),
  itvChannelLink: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  singleChannelPacks: types.maybe(
    types.maybeNull(types.array(SingleChannelPacksModel)),
  ),
  timeshiftDepth: types.maybeNull(types.number),
  adultContent: (types.boolean, false),
  audioStreams: types.maybeNull(types.array(types.string)),
  availablePackets: types.maybeNull(types.array(AvailablePacketsModel)),
  availablePlatforms: types.maybeNull(types.array(types.string)),
  channelDefinition: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  imgLink: types.maybeNull(types.string),
  includedToProduct: types.maybeNull(types.boolean),
  weburgId: types.maybe(types.number),
  channelPackId: types.maybe(types.maybeNull(types.number)),
  banTrimDate: types.maybe(types.maybeNull(types.string)),
  unbindDt: types.maybe(types.maybeNull(types.string)),
  channelPackName: types.maybe(types.maybeNull(types.string)),
  isChannelWrapper: types.maybe(types.boolean),
  isFree: types.maybe(types.boolean),
  isOrdered: types.maybe(types.boolean),
  isSale: types.maybe(types.boolean),
  isTimeshiftIncluded: types.maybe(types.boolean),
  isTrial: types.maybe(types.boolean),
  price: types.maybe(types.maybeNull(types.number)),
});

export const TVChannelsModel = types.model('tvChannels', {
  /** Наименование тематики */
  name: types.maybeNull(types.string),
  /** Индекс сортировки */
  position: types.maybeNull(types.number),
  /** Массив каналов */
  channels: types.maybeNull(types.array(ChannelModel)),
});

export const AllPackageModel = types.model('', {
  id: types.maybeNull(types.number),
  channelPackName: types.maybeNull(types.string),
  isChannelWrapper: (types.boolean, false),
  isFree: (types.boolean, false),
  isOrdered: (types.boolean, false),
  isSale: (types.boolean, false),
  /** Управление просмотром */
  isTimeshiftIncluded: (types.boolean, false),
  isTrial: (types.boolean, false),
  price: types.maybeNull(types.number),
  unbindDt: types.maybeNull(types.string),
  channelPackId: types.maybe(types.maybeNull(types.number)),
  /** id`s подключённых каналов */
  channelIds: types.maybe(types.maybeNull(types.array(types.number))),
  name: types.maybe(types.maybeNull(types.string)),
  channelPackChannels: types.maybe(types.maybeNull(types.array(ChannelModel))),
  banTrimDate: types.maybe(types.maybeNull(types.string)),
});

export const TrialInfoModel = types.model('TrialInfo', {
  isActiveNow: (types.boolean, false),
  channelPackId: types.maybeNull(types.number),
  isAvailable: (types.boolean, false),
});

export const TransformerInfoModel = types.model('TransformerInfo', {
  orderSum: types.maybeNull(types.number),
});
