import { StateFormErrorProps } from '~/components/Blocks/Templates/AutoPayment/types';

// Максимально возможная сумма
export const MAX_AMOUNT_VALUE = '15\u00A0000';
// Минимально возможная сумма
export const MIN_AMOUNT_VALUE = '30';

/** Количество попыток проверки url */
export const CHECK_URL_AMOUNT = 30;

// Обнуление попыток проверки url
export const NO_CHECK_URL_AMOUNT = -1;

// state amount по умолчанию
export const DEFAULT_AMOUNT = {
  value: '',
  forSend: '',
};

// Сообщения об ошибке
export enum ERROR_MESSAGES {
  CONTRACT_NUMBER = 'Укажите номер договора',
  CONTRACT_NOT_FOUND = 'Договор не найден',
  CARD_NOT_FOUND = 'Выберите карту',
  CARD_HAS_EXPIRED = 'Срок действия карты истёк',
  ERROR_LINKED_CARD = 'Нет информации о привязанных картах',
  ERROR_LINKED_ACCOUNTS = 'Нет информации о привязанных сбп-счетах',
  ERROR_BIND_CARD = 'Не получилось привязать карту, попробуйте повторить позже',
  ERROR_UNBIND_CARD = 'Не удалось удалить карту. Проверьте, что к ней не подключён автоплатёж',
  ERROR_UNBIND_SBP = 'Не удалось удалить счёт. Проверьте, что к нему не подключён автоплатёж',
  ERROR = 'Что-то пошло не так',
  NOT_AVAILABLE_TO_EMPLOYEE = 'Недоступно сотруднику',
  BLOCKED_BY_VACATION = 'Услуга невозможна, т.к. подключена услуга Каникулы.',
  DEVICE_NO_PRICES = 'Отсутствуют цены.',
  CONTRACT_NOT_AVAILABLE_FUNDS = ' Недостаточно денег для совершения операции.',
  AUTOPAYMENT_ERROR = 'Не удалось подключить автоплатеж. Попробуйте повторить позже.',
}

// Результаты операции
export enum RESULT {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

// Тексты для сообщения о результате операции
/* yaspeller ignore:start */
// prettier-ignore
export enum RESULT_TEXT {
  SUCCESS = 'Всё хорошо!\nМы получили ваш платёж.',
  FAILURE = 'Опс!\nЧто-то пошло не так...\nПопробуйте повторить платёж через несколько минут.',
}
/* yaspeller ignore:end */

/** идентификатор элемента блока Payment */
export const PAYMENT_BLOCK_ID = '#payment';

/** Тип исключения для ошибки, если договор не найден */
export const CONTRACT_NOT_FOUND_TYPE = 'ContractNotFoundException';

/** Текст "Новая карта" */
export const NEW_CARD = 'Банковская карта';

/** Оплата */
export enum PAYMENT_OPTION {
  /** Привязать новую карту с авторизацией */
  LINK_NEW_CARD_IS_AUTH,
  /** Привязать новую карту без авторизации или другому договору  */
  LINK_NEW_CARD,
  /** Оплатить привязанной картой номер договора */
  LINKED_CARD_CONTRACT_NUMBER,
  /** Оплатить новой картой без привязки */
  NEW_CARD_WITHOUT_BINDING,
}

/** Тип select для выбора варианта оплаты */
export enum PAYMENT_TYPE_INTERFACE {
  TABS = 'tabs',
  SELECT = 'select',
}

/** Выбранный тип оплаты по дефолту */
export enum DEFAULT_PAYMENT {
  /** СБП */
  SBP = 'sbp',
  /** Карта */
  CARD = 'card',
}

/** Вариант оплаты для таба */
export enum PAYMENT_OPTION_TABS {
  CARD,
  SBP,
}

export enum CARD_TEXT {
  BY_CARD = 'Картой',
  BANK_CARD = 'Банковской картой',
  TOP_UP_WITH_A_CARD = 'Пополнить счёт картой',
  TOP_UP_ACCOUNT_WITH_A_BANK_CARD = 'Пополнить счёт банковской картой',
}

export enum SBP_TEXT {
  SBP = 'СБП',
  TOP_UP_VIA_SBP = 'Пополнить через СБП',
  TOP_UP_ACCOUNT_VIA_SPB = 'Пополнить счёт через СБП',
}

/** Промо-акции */
export const NO_PROMOTIONS = 'No Content';

export const DEFAULT_FORM_ERROR_STATE: StateFormErrorProps = {
  amount: {
    error: false,
  },
  agreement: {
    error: false,
  },
};

export const CHECK_PAYMENT_SLUG = 'checkpayment';

export const EXCEPTIONS = {
  DevicePurchaseException: ERROR_MESSAGES.ERROR,
  LeasePayBlockedByVacationException: ERROR_MESSAGES.BLOCKED_BY_VACATION,
};
export const DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION =
  'DevicePurchaseBlockedByVacationException';

export const DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION =
  'DevicePurchaseDeviceNoPricesException';

export const DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION =
  'DevicePurchaseContractNotAvailableFundsException';

export const DEVICE_PURCHASE_EXCEPTION = 'DevicePurchaseException';

/** Иконки карт */
export enum CARD_ICONS {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MIR = 'mir',
  JCB = 'jcb',
  UNIONPAY = 'unionpay',
  NONAME_CARD = 'nonameCard',
  SBP = 'sbp',
}
