import { flow, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import {
  getProlongationAgreement,
  getProlongationInfo,
  prolongate,
} from '~/api/apiPab2c';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';

const RequestsStateModel = types.model('State', {
  getProlongationInfo: createApiPathModel('GET /Tax/Tax/GetProlongationInfo'),
  getProlongationAgreement: createApiPathModel(
    'GET /Tax/Tax/GetProlongationAgreement',
  ),
  prolongate: createApiPathModel('POST /Tax/Tax/Prolongate'),
});

const ProlongationDataModel = types.model('ProlongationData', {
  price: types.number,
  promoDaysInSeries: types.number,
  tariffName: types.string,
});

const TariffDurationChangePromoInfoModel = types.model(
  'TariffDurationChangePromoInfo',
  {
    additionalDays: types.number,
    promoName: types.string,
    finishDt: types.string,
  },
);

const ProlongationInfoModel = types.model('ProlongationInfoModel', {
  prolongation: types.maybeNull(ProlongationDataModel),
  tariffDurationChangePromoInfo: types.maybeNull(
    TariffDurationChangePromoInfoModel,
  ),
});

export const ProlongationStore = types
  .model('ProlongationStore', {
    requestsState: RequestsStateModel,
    prolongationInfoData: types.maybeNull(ProlongationInfoModel),
    /** соглашение о продлении годового продукта */
    agreement: (types.string, ''),
  })
  .views((self) => ({
    get isLoadingProlongationInfo() {
      return self.requestsState.getProlongationInfo.isLoading;
    },
    get isFetchedProlongationInfo() {
      return self.requestsState.getProlongationInfo.isFetched;
    },
    get isLoadingAgreement() {
      return self.requestsState.getProlongationAgreement.isLoading;
    },
    get isLoadingProlongate() {
      return self.requestsState.prolongate.isLoading;
    },
    get prolongationInfo() {
      return toJS(self.prolongationInfoData);
    },
  }))
  .actions((self) => ({
    getProlongation: flow(function* () {
      self.requestsState.getProlongationInfo.reset();
      self.requestsState.getProlongationInfo.setLoading();
      try {
        const res = yield getProlongationInfo();
        self.prolongationInfoData = res;
        self.requestsState.getProlongationInfo.setSuccess();
      } catch (e) {
        console.error('getProlongationInfo', e);
        self.requestsState.getProlongationInfo.setFail();
      }
    }),
  }))
  .actions((self) => ({
    /** Получает соглашение продления продукта */
    getAgreement: flow(function* (
      setIsAnnualProductRenewal,
      seriesName,
      setSeriesName,
    ) {
      self.requestsState.getProlongationAgreement.reset();
      self.requestsState.getProlongationAgreement.setLoading();
      try {
        const res = yield getProlongationAgreement();
        self.agreement = res;
        setIsAnnualProductRenewal(true);
        setSeriesName(seriesName);
        self.requestsState.getProlongationAgreement.setSuccess();
      } catch (e) {
        self.requestsState.getProlongationAgreement.setFail();
      }
    }),
    /** Продление продукта */
    prolongateProduct: flow(function* (
      setResult,
      seriesName,
      getWeburgMeContractInfo,
    ) {
      self.requestsState.prolongate.reset();
      self.requestsState.prolongate.setLoading();
      try {
        const res = yield prolongate();
        if (res.statusCode === 'Success') {
          setResult({
            isResult: true,
            isCorrect: true,
            text: `Ура! Вы продлили продукт ${seriesName}`,
          });
          self.getProlongation();
          getWeburgMeContractInfo();
          self.requestsState.prolongate.setSuccess();
        } else {
          setResult({
            isResult: true,
            isCorrect: false,
          });
        }
        self.requestsState.prolongate.setFail();
      } catch (e) {
        setResult({
          isResult: true,
          isCorrect: false,
        });
        self.requestsState.prolongate.setFail();
      }
    }),
    resetProlongation: () => {
      self.prolongationInfoData = null;
      self.agreement = '';
      self.requestsState.getProlongationInfo.reset();
    },
  }));

export const ProlongationStoreInstance = ProlongationStore.create({
  requestsState: {
    getProlongationInfo: defaultModelState,
    getProlongationAgreement: defaultModelState,
    prolongate: defaultModelState,
  },
  prolongationInfoData: null,
  agreement: '',
});
