/** Библиотеки */
import styled from '@emotion/styled';
import { LeadingText, Snoska } from 'cordis-core-ui-planeta';

/** Стилизованная версия компонента LeadingText для MDX */
export const StyledMdxLeadingText = styled(LeadingText)`
  margin-bottom: 32px;
  display: inline-block;
`;

/** Стилизованная версия компонента Snoska для MDX */
export const StyledMdxSnoska = styled(Snoska)`
  margin-bottom: 24px;
`;

/** Стилизованная версия параграфа для MDX */
export const StyledMdxParagraph = styled.div`
  margin-bottom: 32px;
  span {
    line-height: 24px;
  }
`;
