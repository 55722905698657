import { types, flow } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getWeburgMeContractInfo } from '~/api/apiPab2c';

const RequestsStateModel = types.model('State', {
  getWeburgMeContractInfo: createApiPathModel(
    'GET /Tax/Tax/GetWeburgMeContractInfo',
  ),
});

const PromoTariffStateModel = types.model('PromoTariffState', {
  /** Осталось дней до автоматической смены продукта */
  autoDaysLeft: types.maybeNull(types.number),
  /** Дата окончания действия продукта */
  trimDate: (types.string, ''),
  /** Название продукта, на который будет выполнен автоматический переход по окончании периода действия промо продукта */
  autoTariffName: (types.string, ''),
  /** Цена пролонгации продукта, с учетом механизма продажи годовых с разделением платежа
   *  подробнее: https://ctms.itmh.ru/browse/DEFECT-2131
   */
  prolongationPrice: types.maybeNull(types.number),
});

const WeburgMeContractInfoModel = types.model('WeburgMeContractInfo', {
  /** Пояс обслуживания */
  area: types.maybeNull(types.number),
  /** Признак, подключен ли договор с использованием FVNO */
  isFvno: types.maybeNull(types.boolean),
  /** Информация об использовании промо-продукта */
  promoTariffState: types.maybeNull(PromoTariffStateModel),
  /** Дата начала обслуживания */
  noviceDt: types.maybeNull(types.string),
});

export const ContractInfoStore = types
  .model('ContractInfo', {
    requestsState: RequestsStateModel,
    contractInfo: types.maybeNull(WeburgMeContractInfoModel),
  })
  .views((self) => ({
    get area() {
      return self.contractInfo?.area;
    },
    get isFvno() {
      return self.contractInfo?.isFvno;
    },
    get promoTariffStateAutoDaysLeft() {
      return self.contractInfo?.promoTariffState?.autoDaysLeft;
    },
    get promoTariffStateTrimDate() {
      return self.contractInfo?.promoTariffState?.trimDate;
    },
    get promoTariffStateAutoTariffName() {
      return self.contractInfo?.promoTariffState?.autoTariffName;
    },
    get promoTariffStateProlongationPrice() {
      return self.contractInfo?.promoTariffState?.prolongationPrice;
    },
    get promoTariffStateNoviceDt() {
      return self.contractInfo?.noviceDt;
    },
  }))
  .actions((self) => ({
    getWeburgMeContractInfo: flow(function* () {
      self.requestsState.getWeburgMeContractInfo.reset();
      self.requestsState.getWeburgMeContractInfo.setLoading();
      try {
        const res = yield getWeburgMeContractInfo();
        self.contractInfo = res;
        self.requestsState.getWeburgMeContractInfo.setSuccess();
        return res.noviceDt;
      } catch (e) {
        self.requestsState.getWeburgMeContractInfo.setFail();
        return null;
      }
    }),
    resetContractInfo: () => {
      self.contractInfo = null;
    },
  }));

export const ContractInfoStoreInstance = ContractInfoStore.create({
  requestsState: {
    getWeburgMeContractInfo: defaultModelState,
  },
  contractInfo: null,
});
