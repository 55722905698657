export interface ContractTrustedResumeProps {
  /** Подключена ли услуга в данный момент. */
  /** Возможность подключения услуги. */
  /** Дата начала предоставления услуги при последнем подключении. */
  startDate: string;
  /** Дата окончания предоставления услуги при последнем подключении. */
  /** Продолжительность предоставления услуги в часах. */
  durationHours: number;
  trustedResumeState: TRUSTED_RESUME_STATE;
  durationSecondsLeft: number;
}

export enum TRUSTED_RESUME_STATE {
  unavailable = 'Unavailable',
  available = 'Available',
  activated = 'Activated',
  used = 'Used',
}
