/** библиотеки */
import { HTMLProps, FC, ReactNode } from 'react';
import Link from 'next/link';
import { Link as CordisLink } from 'cordis-core-ui-planeta';

/** утилиты */
import { isExternal } from '~/utils/utils';

/** стилевые компоненты */
import StyledLinkWrapper from './style';

/** Компонент-обёртка для формирования внутренней ссылки */
const LinkWrapper: FC<
  {
    href?: string;
    children: ReactNode;
  } & HTMLProps<HTMLAnchorElement>
> = ({ href, children, target, ...restProps }) => {
  if (!href) return <>{children}</>;

  return isExternal(href) ? (
    <StyledLinkWrapper className={restProps.className}>
      <CordisLink href={decodeURIComponent(href)} target={target ?? '_blank'}>
        {children}
      </CordisLink>
    </StyledLinkWrapper>
  ) : (
    <StyledLinkWrapper className={restProps.className}>
      <Link href={decodeURIComponent(href)} passHref>
        <CordisLink target={target ?? '_self'}>{children}</CordisLink>
      </Link>
    </StyledLinkWrapper>
  );
};

export default LinkWrapper;
