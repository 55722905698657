import { types } from 'mobx-state-tree';

export const AgreementStore = types
  .model('AgreementStore', {
    isShowAgreement: types.boolean,
    customAgreementContent: types.maybeNull(
      types.model({
        title: types.string,
        body: types.string,
      }),
    ),
  })
  .actions((self) => ({
    setIsShowAgreement(value: boolean): void {
      self.isShowAgreement = value;
    },
    setCustomAgreementContent(content: { title: string; body: string }): void {
      self.customAgreementContent = content;
    },
  }));

export const AgreementStoreInstance = AgreementStore.create({
  isShowAgreement: false,
  customAgreementContent: null,
});
